// src/ProductCard.js

import React from 'react';
import { motion } from 'framer-motion';
import './ProductCard.css'; // Ensure this CSS file exists
import { Link } from 'react-router-dom';

const ProductCard = (props) => {
//   const { image, title, price } = product;

  return (
  
    <motion.div
      className="product-card"
      whileHover={{ scale: 1.05, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
      transition={{ duration: 0.3 }}
    >
          <Link to={`/product-details/${props.product.slug}`}>
      <motion.img
        src={props.product.image}
        alt= {props.product.name}
        className="product-image"
        whileHover={{ opacity: 0.8 }}
        transition={{ duration: 0.3 }}
        style={{width:'100%'}}
      />
       </Link>
       <Link to={`/product-details/${props.product.slug}`}>
      <div className="product-details" style={{width:'100%'}}>
        <h2 className="product-title"> {props.product.name}</h2>
        <p className="product-price">Rs.{props.product.price.toFixed(2)}</p>

        {/* <button className="add-to-cart-btn">Add to Cart</button> */}
      </div>
      </Link>
    </motion.div>
   
  );
};

export default ProductCard;
