import React from 'react'

export default function Producttopbanner() {
    return (
        <>
            <div className='prodcsld'>
                <div>
                <h1 className='text-center'>Anti-aging</h1>
<p> em Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  </p>
                </div>
            </div>
        </>
    )
}
