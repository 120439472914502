import React from 'react';
import Layout from './Layout';
import Producttopbanner from './Producttopbanner';
import FilterNav from './FilterNav';
import Productcard from './Productcard';

export default function Products() {
  return (
    <div className='w-100'>
    <Layout>
      
       <Producttopbanner/>
       <FilterNav/>
       <Productcard/>

    </Layout>
    </div>
  )
}
