import React, { useState, useEffect } from "react";
import Collections from "../Component/Collections";
// import Sliderc from "../Component/Slider";
import Homenews from "../Component/Homenews";
import ProductCard1 from "../Component/ProductCard1";
// import homeProduct from "../Component/homeProduct";
import TabsSlider from "../Component/TabsSlider";
// import Footer from "../Component/Footer";
// import Blog from "../Component/Blog";
import Product from "../Component/Product";
import Topslider from "../Component/Topslider";
import Layout from "../Component/Layout";
import moment from "moment";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import axios from "axios";
import ProductCard from "./ProductCard";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },

  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

const responsive1 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },

  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

function Home() {

  // const products = [
  //   {
  //     image: 'https://via.placeholder.com/300',
  //     title: 'Sample Product 1',
  //     price: 29.99,
  //   },
  //   {
  //     image: 'https://via.placeholder.com/300',
  //     title: 'Sample Product 2',
  //     price: 49.99,
  //   },
  // ];

  let currentDate = localStorage.getItem("currentDate");
  if (currentDate == null) {
    localStorage.setItem("currentDate", moment().format("DD-MM-YYYY"));
  }

  const [allHomecategory, setAllHomeCategory] = useState({});
  const [newProduct, setNewProducts] = useState([]);


  const homeCategory = () => {
    axios
      .get(`${BASE_URL}getAllHomeCategory`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
        } else {
          setAllHomeCategory(response.data.data);
          console.log("all homecategory", response.data.data);
        }
      });
  };

  const getNewproducts = () => {
    axios
      .get(`${BASE_URL}newproducts`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
        } else {
          setNewProducts(response.data.data);
          console.log("new products", response.data.data);
        }
      });
  };


  useEffect(() => {
    homeCategory();
    getNewproducts();
  }, []);

  return (
    <>
      <Layout>
        <Topslider />

        {/* new elemnt */}
        <div className="parallax colored-bg pattern-bg" data-stellar-background-ratio="0.5" style={{ backgroundPosition: '-25px 253.5px' }}><div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="section-title animate-from-bottom animation-from-bottom" data-animation-direction="from-bottom" data-animation-delay={50}>
                {/* Beauty Tips For All Skin Type */}
                Best Personal Care Products
                </h1>
              <div id="property-listing" className="grid-style1 clearfix">
                <div className="row">

                  {allHomecategory.length > 0 &&
                    allHomecategory.map((item, index) => (
                      <div className="item1 col-sm-2" key={index}>
                        <div className="image">
                          <a href={`/home-category-view/${item.slug}`} className="info">
                            {/* <img src="https://www.ethicare.in/wp-content/uploads/dryicon.png" className="center no-width" alt style={{ width: '40px', paddingTop: '20px' }} /> */}
                            <h4 className="center"> {item.name}</h4>
                          </a>

                          <img src={item.image} alt="Tips For Dry Skin" />

                        </div>

                      </div>
                    ))
                  }

                  {/* <div className="item1 col-sm-3">
                    <div className="image">
                      <a href="https://www.ethicare.in/beauty-tips/for-oily-skin" className="info">
                        <img src="https://www.ethicare.in/wp-content/uploads/oilyicon.png" className="center no-width" alt style={{ width: '40px', paddingTop: '20px' }} />
                        <h3 className="center">Tips For Olily Skin</h3>
                      </a>
                      <img src="https://www.ethicare.in/wp-content/uploads/oilyskin.jpg" alt="Tips For Olily Skin" />

                    </div>
                  </div>
                  <div className="item1 col-sm-3">

                    <div className="image">

                      <a href="https://www.ethicare.in/beauty-tips/for-hair-care" className="info">

                        <img src="https://www.ethicare.in/wp-content/uploads/hairicon.png" className="center no-width" alt style={{ width: '40px', paddingTop: '20px' }} />

                        <h3 className="center">Tips For Hair Care</h3>

                      </a>
                      <img src="https://www.ethicare.in/wp-content/uploads/haircare.jpg" alt="Tips For Hair Care" />

                    </div>

                  </div>

                  <div className="item1 col-sm-3">

                    <div className="image">

                      <a href="https://www.ethicare.in/beauty-tips/for-sun-protection" className="info">

                        <img src="https://www.ethicare.in/wp-content/uploads/sunicon.png" className="center no-width" alt style={{ width: '40px', paddingTop: '20px' }} />
                        <h3 className="center">Tips For Sun Protection</h3>
                      </a>

                      <img src="https://www.ethicare.in/wp-content/uploads/sunprotection.jpg" alt="Tips For Sun Protection" />
                    </div>
                  </div> */}


                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        {/* new element */}

        {/* new arival product */}
        <div className="parallax pattern-bg" data-stellar-background-ratio="0.5" style={{ backgroundPosition: '-25px 84px' }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="section-title animate-from-bottom animation-from-bottom" data-animation-direction="from-bottom" data-animation-delay={50}>Latest Arrival</h1>
              </div>
              {/*  */}
              <Carousel id="parentsdiv" responsive={responsive1}  autoPlaySpeed={4000} autoPlay infinite={true}>
                {newProduct.filter(productItem => productItem.is_deleted === false).map((product, index) => (
                  <ProductCard key={index} product={product} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>

        {/* new arival product */}
        {/* <TabsSlider /> */}
        {/* <div className="container"> */}
        {/* <div className="row">
          <div className="col-md-6 col-xs-6">
            <img
              src="./img/side_banner_1.png"
              alt=""
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-6 col-xs-6">
            <img
              src="./img/side_banner_2.png"
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div> */}

        <div className="content"><div className="container"><div className="row"> <div className="col-sm-6">

          <h1 className="section-title animate-from-bottom animation-from-bottom" data-animation-direction="from-bottom" data-animation-delay={50} style={{ color: '#1D1B1B' }}>Latest Blog</h1>

          <div className="grid-style1 clearfix">
            <div className="item1 col-md-12 animate-from-bottom animation-from-bottom" data-animation-direction="from-bottom" data-animation-delay={200}>

              <div className="image">
                <a href="#">
                
                <h3>Glow Up: Essential Skincare Tips for Radiant Skin</h3>
                
                 <span className="location">Unlock the secrets to glowing, healthy skin with our essential skincare tips! From identifying your skin type and choosing the right cleanser to mastering the art of moisturizing and sun protection, this blog covers everything you need for a flawless complexion. Discover practical advice on hydration, exfoliation, and creating a personalized skincare routine to achieve and maintain radiant skin. Embrace these tips for a healthier, more vibrant you!</span></a>

                {/* <img width={721} height={401} src="https://www.ethicare.in/wp-content/uploads/Listening-to-Your-Patients-Well-Your-Exceptional-Competitive-Point-Why-and-How-By-Ilesh-Khakhkhar-dermatalk.jpg" className="fix-height wp-post-image" alt="Home" decoding="async" srcSet="https://www.ethicare.in/wp-content/uploads/Listening-to-Your-Patients-Well-Your-Exceptional-Competitive-Point-Why-and-How-By-Ilesh-Khakhkhar-dermatalk.jpg 721w, https://www.ethicare.in/wp-content/uploads/Listening-to-Your-Patients-Well-Your-Exceptional-Competitive-Point-Why-and-How-By-Ilesh-Khakhkhar-dermatalk-350x195.jpg 350w, https://www.ethicare.in/wp-content/uploads/Listening-to-Your-Patients-Well-Your-Exceptional-Competitive-Point-Why-and-How-By-Ilesh-Khakhkhar-dermatalk-300x167.jpg 300w" sizes="(max-width: 721px) 100vw, 721px" /> */}
                <img width={721} height={401} src="./img/blog-2.png" className="fix-height wp-post-image" alt="Home" decoding="async" srcSet="./img/blog-2.png" sizes="(max-width: 721px) 100vw, 721px" />
              </div>
            </div>
          </div>
        </div>


          <div className="col-sm-6"> <div className="col-sm-12">

            {/*
                    <h1 className="section-title animate-from-bottom animation-from-bottom" data-animation-direction="from-bottom" data-animation-delay={50} style={{color:'#1D1B1B'}}>
                      Latest Video</h1> 
                   */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            {/* <iframe width="100%" height={420} src="https://www.youtube.com/embed/p_PPODTHWPU?enablejsapi=1&origin=https%3A%2F%2Fwww.ethicare.in" frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen data-gtm-yt-inspected-59005128_11="true" id={818329932} title="Ethicare Remedies - Bringing You Closer To your Self" /> */}
            <img src="./img/side_banner_1.png" width="100%" height={420} />



          </div>
          </div>
        </div>
        </div>
        </div>

        {/* </div> */}

        {/* <homeProduct /> */}
        {/* <div className="container-fluid pt-5">
          <div className="row">
            {allHomecategory.length > 0 &&
              allHomecategory.map((item, index) => (
                <>
                  {item.products.length > 0 ? (
                    <div className="col-md-12" key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ fontWeight: 600, fontSize: "25px" }}>
                          {item.name}
                        </h3>
                        <Link
                          to={`/home-category-view/${item.slug}`}
                          style={{ fontSize: "16px", fontWeight: 500 }}
                        >
                          View All
                        </Link>
                      </div>
                      <Carousel id="parentsdiv" responsive={responsive}>
                        {item.products.length > 0 &&
                          item.products.map((productItem, i) => (
                            <ProductCard1 key={i} product={productItem} />
                          ))} 
                        {item.products.length > 0 &&
                          item.products
                            .filter(productItem => productItem.is_deleted === false) // Filter products where isDelete is false
                            .map((productItem, i) => (
                              // <ProductCard1 key={i} product={productItem} />
                              <ProductCard key={index} product={productItem} />
                            ))
                        }
                      </Carousel>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </div>
        </div> */}
        {/* <Product /> */}
        <Collections />

        <Homenews />

        {/* <Sliderc/> */}
        {/* <Productcard/> */}
        {/* <Blog/> */}
      </Layout>
    </>
  );
}
export default Home;
