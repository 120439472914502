import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import IndianStates from "../data/IndianStates.json";

const initialState = {
  name: "",
  mobile_no: "",
  email: "",
  address: "",
  address2: "",
  country: "India",
  state: "",
  city: "",
  zip_code: "",
  amount: 0,
  // discount_amount: 0,
  total_amount: 0,
  // shipping_charge:0,
  delivery_type: "",
  payment_status: "Pending",
};

export default function Checkout() {
  const navigate = useNavigate();
  const [cartItem, setCartItem] = useState([]);
  const [states, setStates] = useState(IndianStates);
  const [totalAmount, setTotalAmount] = useState(0);
  const [values, setValues] = useState(initialState);
  const [loading, setLoding] = useState(false);
  const [shipCharges, setShipCharges] = useState([]);
  const [shipAmount, setShipAmount] = useState();
  const [delivery_type, setDelivery_type] = useState("");

  let amount = 0;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };

  const loadProfile = () => {
    let token = localStorage.getItem("token");

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}auth/view-profile`,

        {
          headers: {
            "x-access-token": ` ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.success === false) {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
          // toast.error(response.data.message);
        } else {
          setValues(response.data.data);
          console.log("values", values);
          // setValues({customer_name:})
          // toast.success(response.data.message);
          // localStorage.setItem("user", response.data.data);
          // navigate("/");
        }
        // console.log("response =>", response.data.success);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };

  const loadCartList = () => {
    let tmpID = localStorage.getItem("tmpID");

    let obj = {
      tmpUserID: tmpID,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}getAllCart`, obj)
      .then(function (response) {
        setCartItem(response.data.data);
        let valueAdded = 0;
        for (let i = 0; i < response.data.data.length; i++) {
          valueAdded =
            valueAdded +
            response.data.data[i].Product_details[0].price *
              response.data.data[i].quantity;
        }
        if (valueAdded != 0) {
          setTotalAmount(valueAdded);
          console.log("amount", valueAdded);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const createOrder = () => {
    let token = localStorage.getItem("token");
    if (!delivery_type) {
      toast.error("Payment Method Is Required");
      return;
    }
    // if (!values.mobile_no||values.mobile_no=="") {
    //     toast.error("Mobile Number Is Required");
    //     return;
    // }
    if (!values.name) {
      toast.error("Full Name Is Required");
      return;
    }

    if (!values.address) {
      toast.error("Address Is Required");
      return;
    }
    if (!values.state) {
      toast.error("State Is Required");
      return;
    }
    if (!values.city) {
      toast.error("City Is Required");
      return;
    }
    if (!values.zip_code) {
      toast.error("Pincode Is Required");
      return;
    }

    // setValues({ ...values, amount: totalAmount });
    // setValues({ ...values, total_amount: totalAmount });
    // setValues({ ...values, cart: cartItem });
    // setValues({ ...values, delivery_type: delivery_type });

    values.amount = totalAmount;
    values.total_amount =
      parseInt(totalAmount) + (parseInt(shipAmount) ? parseInt(shipAmount) : 0);
    values.cart = cartItem;
    values.delivery_type = delivery_type;
    // console.log("values ===>",values);
    // return;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}createOrder`,
        values,

        {
          headers: {
            // version: "1.0.0",
            "x-access-token": ` ${token}`,
            "Content-Type": "Application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.success === false) {
          // toast.error(response.data.message);
          setLoding(false);
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        } else {
          // toast.success(response.data.message);
          if (delivery_type == "Prepaid") {
            console.log("response order create =>", response.data.data);

            const options = {
              key: "rzp_live_T2nObuzrpCexbS",
              amount: parseInt(values.total_amount) * 100, // Amount in paise
              currency: "INR",
              name: "Skin Science Laboratory",
              description:
                "Skin Science Medical Cosmetics is an Indian skincare company specializing in dermatological",
              order_id: response.data.data.orderId, // The order ID obtained from the server
              handler: function (response1) {
                // Handle the payment success
                // console.log(response1);

                // Handle the payment success
                // console.log("Payment Successful");

                //updatePaymentGetawayStatus
                axios
                  .post(
                    `${process.env.REACT_APP_BASE_URL}updatePaymentGetawayStatus`,
                    {
                      razorpay_payment_id: response1.razorpay_payment_id,
                      order_id: response.data.data.order_id,
                    },
                    {
                      headers: {
                        // version: "1.0.0",
                        "x-access-token": ` ${token}`,
                        "Content-Type": "Application/json",
                      },
                    }
                  )
                  .then(function (response) {
                    if (response.data.success === false) {
                    } else {
                      toast.success(response.data.message);
                       navigate("/orders");
                    }
                  });
                console.log(response);

                // Additional code to handle success
                // handlePaymentSuccess(response);
              },
              prefill: {
                name: values.name,
                email: values.email,
                contact: values.mobile_no,
              },
              modal: {
                ondismiss: function () {
                  // Handle the payment failure
                  // console.log("Payment Failed or was Cancelled");
                  toast.error("Payment Failed or was Cancelled");

                  navigate("/");

                  // Additional code to handle failure
                  // handlePaymentFailure();
                },
              },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
          } else if (delivery_type == "COD") {
            navigate("/orders");
          }
          //  setLoding(false);
          // navigate("/orders");

          setValues(initialState);
        }
        // console.log("response =>", response.data.data);
      })
      .catch(function (error) {
        toast.error(error);
        console.log(error);
        // setLoding(false);
      });
  };

  useEffect(() => {
    // setTotalAmount(amount);
    console.log("amount", amount);
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    // console.log("IndianStates ==>", IndianStates);
  }, [amount]);

  const getShippingCharges = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getShipmentcharges`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setShipCharges(response.data.data);
          console.log("shipping charges", response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    loadCartList();
    loadProfile();
    getShippingCharges();
  }, []);

  const DeliveryType = (value) => {
    console.log("in");
    setDelivery_type(value);
    if (value == "Prepaid") {
      if (
        parseInt(totalAmount) <=
        parseInt(shipCharges.free_shipping_order_amount)
      ) {
        setShipAmount(shipCharges.prepaid_charge);
      }
      // console.log(shipCharges.prepaid_charge)
    }
    if (value == "COD") {
      if (
        parseInt(totalAmount) <=
        parseInt(shipCharges.free_shipping_order_amount)
      ) {
        setShipAmount(shipCharges.cod_charge);
      }
      // console.log(shipCharges.cod_charge)
    }
  };
  return (
    <>
      <Layout>
        <div
          className=""
          // style={{ backgroundColor: "#c3c5ca" }}
        >
          <div className="container pt-5 pb-5 mb-5">
            <div
              className=""
              style={{
                backgroundColor: "#f2f2f2",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              <div className="row p-5">
                <div className="col-md-8 p-4">
                  <Form>
                    <div>
                      <h1 className="p-3 text-center">
                        <u>Billing details</u>
                      </h1>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Payment method</Form.Label>

                          <select
                            className="checkoInput"
                            id="delivery_type"
                            name="delivery_type"
                            required=""
                            value={values.delivery_type}
                            onChange={(e) => DeliveryType(e.target.value)}
                          >
                            <option>--Select Payment Method--</option>
                            <option value="Prepaid">Online Payment</option>
                            {/*  <option value="COD">Cash On Delivery</option>*/}
                          </select>
                          {/* <input type="number" placeholder="Enter Pincode "
                                                        onChange={handleChange}
                                                        value={values.Pin_code}
                                                        name='Pin_code'
                                                        className='checkoInput' /> */}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="py-2">Full name</Form.Label>
                          <input
                            type="text"
                            placeholder="Enter name"
                            onChange={handleChange}
                            name="name"
                            value={values.name}
                            className="checkoInput"
                          />
                        </Form.Group>
                      </div>
                      <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="py-2">
                            Mobile number
                          </Form.Label>
                          <input
                            type="number"
                            placeholder="Enter mobile no."
                            onChange={handleChange}
                            name="mobile_no"
                            value={values.mobile_no}
                            className="checkoInput"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="py-2 pt-3">
                        Email address
                      </Form.Label>
                      <input
                        type="email"
                        placeholder="Enter email"
                        onChange={handleChange}
                        name="email"
                        value={values.email}
                        className="checkoInput"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="py-2 pt-3"> Address 1</Form.Label>
                      <input
                        type="text"
                        placeholder="Enter address 1"
                        onChange={handleChange}
                        name="address"
                        value={values.address}
                        className="checkoInput"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="py-2 pt-3"> Address 2</Form.Label>
                      <input
                        type="text"
                        placeholder="Enter address 2"
                        onChange={handleChange}
                        name="address2"
                        value={values.address2}
                        className="checkoInput"
                      />
                    </Form.Group>

                    <div className="row">
                      <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="py-2 pt-3">state</Form.Label>
                          <select
                            className="checkoInput"
                            id="state"
                            name="state"
                            required
                            onChange={handleChange}
                          >
                            <option disabled>Choose...</option>
                            {states.length > 0 &&
                              states.map((item) => (
                                <option
                                  key={item.key}
                                  value={item.name}
                                  selected={
                                    values.state == item.name ? true : false
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </Form.Group>
                      </div>
                      <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="py-2 pt-3">city</Form.Label>
                          <input
                            type="text"
                            placeholder="Enter city"
                            onChange={handleChange}
                            name="city"
                            value={values.city}
                            className="checkoInput"
                          />
                        </Form.Group>
                      </div>

                      <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="py-2 pt-3">
                            Pin code
                          </Form.Label>
                          <input
                            type="number"
                            placeholder="Enter Pincode "
                            onChange={handleChange}
                            value={values.zip_code}
                            name="zip_code"
                            className="checkoInput"
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <br />
                    <br />

                    {loading ? (
                      <Spinner animation="border" />
                    ) : (
                      <Button
                        variant="success"
                        type="button"
                        style={{ width: "100%", padding: "10px" }}
                        disabled={loading ? true : false}
                        onClick={() => createOrder()}
                      >
                        <h3> Place order</h3>
                      </Button>
                      // <button
                      //   className="btn btn-primary btn-lg btn-block"
                      //   onClick={() => createOrder()}

                      //   type="button"
                      //   disabled={(loading)?true:false
                      // }
                      // >
                      //   Place Your Order
                      // </button>
                    )}
                  </Form>
                </div>
                <div className="col-md-4 p-4">
                  <h1 className="p-4">
                    <u>Cart summary</u>
                  </h1>
                  <div className="p-3 bg-white">
                    <Form>
                      {cartItem.length > 0 &&
                        cartItem.map((item, index) => (
                          <>
                            <div className="row p-3">
                              <div className="col">
                                {item.Product_details[0].name}
                              </div>
                              <div className="col text-end">
                                {item.quantity} x{" "}
                                {item.Product_details[0].price} ={" "}
                                <i className="fa fa-inr"></i>
                                {item.quantity * item.Product_details[0].price}
                              </div>
                            </div>
                            <hr />
                          </>
                        ))}
                      <div className="row p-3">
                        <div className="col">Total (INR)</div>
                        <div className="col text-end">
                          {totalAmount}&nbsp; RS/-
                        </div>
                      </div>
                      <hr />
                      {shipAmount > 0 ? (
                        <>
                          <div className="row p-3">
                            <div className="col paraS">Shipping Amount</div>
                            <div className="col text-end paraS">
                              {shipAmount ? shipAmount : 0}&nbsp; RS/-
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {shipAmount ? (
                        <div className="row p-3">
                          <hr />
                          <div className="col paraS">Total Amount (INR)</div>
                          <div className="col text-end paraS">
                            {parseInt(totalAmount) + parseInt(shipAmount)}&nbsp;
                            RS/-
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
