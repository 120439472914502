// import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import "react-perfect-scrollbar/dist/css/styles.css";
import "react-multi-carousel/lib/styles.css";
import "react-tabs/style/react-tabs.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sliderc from "./Component/Slider";
import Productcard from "./Component/Productcard";
import NavMenu from "./Component/NavMenu";
import ProductCard1 from "./Component/ProductCard1";
import Homenews from "./Component/Homenews";
import Collections from "./Component/Collections";
import Home from "./Pages/Home";
import TabsSlider from "./Component/TabsSlider";
import ProductDetail from "./Pages/ProductDetail";
// import Blog from './Pages/Blog';
// import { ToastContainer } from 'react-toastify';
import { ToastContainer, toast } from "react-toastify";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import FilterNav from "./Component/FilterNav";
import Products from "./Component/Products";
import Register from "./Pages/auth/Register";
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import CategoriesView from "./Pages/CategoriesView";
import Addtocart from "./Pages/Addtocart";
import Checkout from "./Pages/Checkout";
import Myprofile from "./Pages/Myprofile";
import Login from "./Pages/auth/Login";
import Orders from "./Pages/Orders";
import Changepassword from "./Pages/Changepassword";
import Subcategoryview from "./Pages/Subcategoryview";
import FilterNavCopy from "./Component/FilterNavcopy";
import HomecategoryView from "./Pages/HomecategoryView";
import SearchProduct from "./Pages/SearchProduct";
import ProductsInfo from "./Pages/ProductsInfo";
// import NavMenucopysubmenuchildmenu from './Component/NavMenucopysubmenu&childmenu';
import Childcategories from "./Pages/Childcategories";
import Childcategoryview from "./Pages/Childcategoryview";
import Aboutus from "./Pages/Aboutus";
import Blog from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import Contactus from "./Pages/Contactus";
import ProductDetailcopy from "./Pages/ProductDetailcopy";
const webinfo = createContext();
const categories = createContext();

const CartContext = createContext();

function App() {
  const [websiteInfo, setWebsiteInfo] = useState([]);
  const [category, setCategory] = useState([]);
  const [cartlen, setCartlen] = useState();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let tmpID = localStorage.getItem("tmpID");
  if (tmpID == null) {
    let r = (Math.random() + 1).toString(36).substring(7);
    localStorage.setItem("tmpID", r + Date.now());
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/About-Us",
      element: <Aboutus />,
    },
    // {
    //   path:"/blog",
    //   element:<Blog/>
    // },
    {
      path: "/product-details/:slug",
      element: <ProductDetail />,
    },
    {
      path: "/tabslider",
      element: <TabsSlider />,
    },
    {
      path: "/filternav",
      element: <FilterNav />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/category/:slug",
      element: <CategoriesView />,
    },
    {
      path: "/subcategoryview/:categorySlug/:subCategorySlug",
      element: <Subcategoryview />,
    },
    {
      path: "/childcategoryview/:slug",
      element: <Childcategoryview />,
    },
    {
      path: "/home-category-view/:slug",
      element: <HomecategoryView />,
    },
    {
      path: "/addtocart",
      element: <Addtocart />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/myprofile",
      element: <Myprofile />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/orders",
      element: <Orders />,
    },
    {
      path: "/change-password",
      element: <Changepassword />,
    },
    {
      path: "/filterNav-copy",
      element: <FilterNavCopy />,
    },
    {
      path: "/product-search",
      element: <SearchProduct />,
    },
    {
      path: "/products-info",
      element: <ProductsInfo />,
    },
    {
      path: "/child-categories/:slug",
      element: <Childcategories />,
    },
    {
      path: "/blogs",
      element: <Blog />,
    },
    {
      path: "/blog-detail/:slug",
      element: <BlogDetails />,
    },
    {
      path: "/contact-us",
      element: <Contactus />,
    },
    {
      path: "/product-copy",

      element: <ProductDetailcopy />,
    },
  ]);
  const getAllcategories = () => {
    axios
      .get(`${BASE_URL}getAllCategory`, {
        headers: {
          "Content-Type": "application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          // console.log()
        } else {
          setCategory(response.data.data);
          console.log("categories", response.data.data);
        }
      });
  };

  const webSiteinfo = () => {
    axios
      .get(`${BASE_URL}getWebsiteInfo`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          console.log(response.data.message);
          console.log("website info", response.data.data);
          setWebsiteInfo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadCartList = () => {
    let tmpID = localStorage.getItem("tmpID");
    const obj = {
      tmpUserID: tmpID,
    };
    axios
      .post(`${BASE_URL}getAllCart`, obj, {
        headers: {
          version: "1.0.0",
          "Content-type": "Application/json",
        },
      })
      .then(function (response) {
        //  setCartItem(response.data.data);
        //  console.log("cart products:=================>", cartItem);
        setCartlen(response.data.data.length);
        // console.log(response.data.data.length);
        // console.log("cart-cart-len",cartlen);
        // console.log("cart-length",response.data.data.length);
        // console.log("response image is",response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    webSiteinfo();
    getAllcategories();
  }, []);

  return (
    <>
      <CartContext.Provider value={{ loadCartList, cartlen }}>
        <webinfo.Provider value={websiteInfo}>
          <categories.Provider value={category}>
            <RouterProvider router={router} />
            <ToastContainer />
          </categories.Provider>
        </webinfo.Provider>
      </CartContext.Provider>
    </>
  );
}

export default App;
export { webinfo };
export { categories };
export { CartContext };
