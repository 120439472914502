import React, { useEffect, useState, createContext } from "react";
import ProductCard1 from "../Component/ProductCard1";
import productdet from "../data/newProductSlider.json";
import Layout from "../Component/Layout";
import Breadcrumb from "../Component/Breadcrumbc";
import Breadcrumbc from "../Component/Breadcrumbc";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import girlimage from "./girl-applying-serum.png";

function ProductDetailcopy() {
  const navigate = useNavigate();
  const [stepstouse, setSteptouse] = useState([]);

  const slug = useParams();
  const [loading, setLoding] = useState(false);
  const [productinfo, setProductInfo] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState("");

  console.log("product deatil slug", slug.slug);

  const proDetail = () => {
    axios
      .get(`${BASE_URL}ProductView/${slug.slug}`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setProductInfo(response.data.data);
          console.log("product deatils information", response.data.data);
          setId(response.data.data._id);
          console.log(
            "product id for howtouse==========>",
            response.data.data._id
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addToCart = (product, type) => {
    // if (user == null) {
    //   navigate("/sign-in");
    //   return;
    // }
    let tmpID = localStorage.getItem("tmpID");

    setLoding(true);
    console.log("product ==>", product);
    let obj = {
      productId: product._id,
      quantity: 1,
      tmpUserID: tmpID,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}addToCart`, obj)
      .then(function (response) {
        // handle success
        if (response.data.success == true) {
          toast.success("Product added to cart successfully");
          setLoding(false);
          if (type == 2) {
            navigate("/addtocart");
          }
        } else {
          toast.error("You have already added this product in your cart");
          setLoding(false);
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };

  const getStepstoUse = () => {
    axios
      .get(`${BASE_URL}admin/getHowToUsebyids/${id}`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
        } else {
          setSteptouse(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    proDetail();
  }, []);

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  useEffect(() => {
    getStepstoUse();
  }, [productinfo]);
  return (
    <>
      <Layout>
        <div
          className="indicatenav "
          style={{
            backgroundColor: "#94a5c1",
            color: "#fff",
            fontWeight: "600",
            position: "sticky",
            top: "0",
            zIndex: "2",
          }}
        >
          <ul className="nav flex-row justify-content-center">
            <li className="nav-item mb-2 ps-3 p-3">
              <a
                href="#indicate-to-me"
                className="inlink nav-link p-0  text-body-secondary"
              >
                Is it indicated for me?
              </a>
            </li>
            <li className="nav-item mb-2 p-3">
              <a
                href="#information"
                className=" inlink nav-link p-0 text-body-secondary"
              >
                Information
              </a>
            </li>
            {/* <li className="nav-item mb-2 p-3"><a href="#" className="inlink nav-link p-0 text-body-secondary">Eficacia probada</a></li> */}
          </ul>
        </div>

        <Breadcrumbc />

        <div className=" ">
          <div className="row ps-5 pe-5" style={{ backgroundColor: "#f9f9f9" }}>
            <div className="col-md-6 mt-5 order-2 order-sm-2 order-xs-2  order-md-1 order-lg-1 order-xl-1 order-xxl-1 ">
              <div
                style={{
                  fontWeight: "700",
                  color: "#000",
                  fontSize: "clamp(25px, 25px, 35px)",
                }}
              >
                {productinfo?.name}
              </div>
              <p
                style={{
                  color: "#73828e",
                  lineHeight: "2.1",
                  letterSpacing: "3",
                  wordSpacing: "2",
                }}
              >
                {renderHTML(productinfo?.long_decription)}
              </p>
              <p
                style={{
                  color: "#73828e",
                  lineHeight: "2.1",
                  letterSpacing: "3",
                  wordSpacing: "2",
                }}
              >
                {renderHTML(productinfo?.short_decription)}
              </p>
              <div className="d-flex m-2">
                {productinfo.quantity > 0 ? (
                  loading ? (
                    <Spinner animation="border" />
                  ) : (
                    <button
                      type="button"
                      className="loginbtn m-2"
                      style={{ width: "180px", padding: "15px" }}
                      onClick={() => addToCart(productinfo, "1")}
                    >
                      Add to cart <i className="fa fa-shopping-cart m-2"></i>
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="  loginbtn m-2"
                    disabled
                    style={{ width: "180px", padding: "15px" }}
                  >
                    Out of stock <i className="fa fa-shopping-cart"></i>
                  </button>
                )}
                {productinfo.quantity > 0 ? (
                  loading ? (
                    <Spinner animation="border" />
                  ) : (
                    <button
                      type="button"
                      className="loginbtn m-2"
                      style={{ width: "180px", padding: "15px" }}
                      onClick={() => addToCart(productinfo, "2")}
                    >
                      Buy now{" "}
                      <span
                        class="material-symbols-outlined "
                        style={{ fontSize: "larger" }}
                      >
                        shopping_bag
                      </span>
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="loginbtn m-2"
                    style={{ width: "180px", padding: "15px" }}
                    onClick={() => addToCart(productinfo, "2")}
                    disabled
                  >
                    Buy now{" "}
                    <span class="material-symbols-outlined">shopping_bag</span>
                  </button>
                )}
              </div>
            </div>
            <div className="stickyproductdescimg col-md-6 order-1 order-xs-1 order-sm-1 order-md-2 order-lg-2 order-xl-2 order-xxl-2">
              {/* <span class="material-symbols-outlined hearticon">favorite</span> */}
              <div style={{ position: "relative", maxWidth: "100%" }}>
                <div>
                  <div>
                    {productinfo &&
                    productinfo.image &&
                    productinfo.image.length > 0 ? (
                      <img
                        src={productinfo.image[0]}
                        alt="image"
                        className="image"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <p>No image available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-5 w-100" id="indicate-to-me">
            <div className="col-md-5">
              {productinfo &&
              productinfo.image &&
              productinfo.image.length > 0 ? (
                <img src={productinfo.image[1]} className="w-100" alt="" />
              ) : (
                <p>No image available</p>
              )}
              {productinfo && productinfo.image && productinfo.image[1] ? (
                <></>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "400px" }}
                >
                  <div>No Image Available</div>
                </div>
              )}
              {/* <img src={productinfo.image.length>0?`${productinfo.image[1]}`:""}className='w-100' alt='' /> */}
            </div>
            <div className="col-md-7 pt-5 p-5" style={{ lineHeight: "2.1" }}>
              <h5 style={{ fontSize: "clamp(20px, 25px, 30px)" }}>
                Is it indicated for me?
              </h5>

              <div
                className="pt-5"
                style={{ color: "#73828e", letterSpacing: "1" }}
              >
                <div>{renderHTML(productinfo?.benefits)}</div>
              </div>

              <div className="row">
                {stepstouse?.length > 0 &&
                  stepstouse.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className={`col-md-6 col-xs-6 col-sm-6 col- pt-5 pe-5 ${
                          index < stepstouse.length - 1 ? "border-bottom" : ""
                        }`}
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "space-around",
                          borderRight:
                            index % 2 == 0 ? "1px solid #73828e" : "",
                        }}
                      >
                        <img
                          src={item.image}
                          className="img-fluid"
                          alt=""
                          width={40}
                          height={40}
                        />
                        <span style={{ fontSize: "23px" }}>{item.name}</span>
                      </div>
                      {/* <div className='col pt-5 pe-5' style={{ display: "flex", justifyContent: "space-around", borderRight: "1px solid #73828e" }}>
                        <span className="material-symbols-outlined" >
                           emoji_nature
                        </span>
                        <span style={{ fontSize: "23px" }}>Dropper</span>
                     </div> */}
                    </>
                  ))}
              </div>
              {/* <div className='row'>
                     <div className='col pt-5 pe-5' style={{ display: "flex", justifyContent: "space-around", borderRight: "1px solid #73828e" }}>
                        <span className="material-symbols-outlined" >
                           face
                        </span>
                        <span style={{ fontSize: "23px" }}>Face</span>
                     </div>
                     <div className='col pt-5 pe-5' style={{ display: "flex", justifyContent: "space-around", borderRight: "1px solid #73828e" }}>
                        <span className="material-symbols-outlined" >
                           emoji_nature
                        </span>
                        <span style={{ fontSize: "23px" }}>Dropper</span>
                     </div>

                  </div> */}
            </div>
          </div>
          <div
            className="row pt-3 "
            style={{ color: "#fff", backgroundColor: "#7e8fae" }}
            id="information"
          >
            <div className="col-12 col-xs-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-12 p-5">
              <h2
                className="ps-3"
                style={{ fontSize: "clamp(20px, 25px, 30px)" }}
              >
                Do you need more information?
              </h2>
              <div className="pt-5 pe-5">
                {/* accordian start  */}
                <details style={{ borderBottom: "1px solid #fff" }}>
                  <summary>
                    <h2 className="mb-4">How to use</h2>
                  </summary>

                  <div className="pt-3">
                    <p>{renderHTML(productinfo.howtouse)}</p>
                  </div>
                </details>

                <details style={{ borderBottom: "1px solid #fff" }}>
                  <summary>
                    <h2 className="mb-4">Benefits</h2>
                  </summary>
                  <div className="pt-3">
                    <p>{renderHTML(productinfo.benefits)}</p>
                  </div>
                </details>

                <details style={{ borderBottom: "1px solid #fff" }}>
                  <summary>
                    <h2 className="mb-4">Compositions</h2>
                  </summary>
                  <div className="pt-3 ">
                    <p className="">{renderHTML(productinfo.compositions)}</p>
                  </div>
                </details>
                <details style={{ borderBottom: "1px solid #fff" }}>
                  <summary>
                    <h2 className="mb-4">ingredients</h2>
                  </summary>
                  <div className="pt-3">
                    <p>{renderHTML(productinfo.ingredients)}</p>
                  </div>
                </details>
                {/* accordian end  */}
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-12 p-3">
              <div className="m-5">
                <img src={girlimage} className="w-100 h-60" alt="" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default ProductDetailcopy;

// export const useCart = () => {
//    return useContext(CartContext);
//  };

// export {cty};
