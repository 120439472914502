import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function ProductCard1(props) {
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  // console.log(props.product);

  return (
    <>
      <Link to={`/product-details/${props.product.slug}`}>
        <div
          className={props.hoverstatus == "inactive" ? "" : "procontainer"}
          style={{ position: "relative", maxWidth: "100%" }}
        >
          <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded">
            <div>
              <img
                src={props.product.image}
                alt="image"
                className="image"
                style={{ width: "100%" }}
              />
            </div>
            <h4
              className={props.hoverstatus == "inactive" ? "d-none" : ""}
              style={{ fontWeight: "700", padding: "10px" }}
            >
              {props.product.name}
            </h4>
            <div className="overlay d-flex justify-content-center align-items-center">
              <div className="text ">
                <div className="container">
                  <div className="row  ">
                    {props.product.howtouses &&
                      props.product.howtouses.length > 0 &&
                      props.product.howtouses.map((item, index) => (
                        <>
                          <div
                            className="col-12 p-3"
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <span className="material-symbols-outlined">
                                                            face
                                                        </span> */}
                            <img
                              src={item.image}
                              alt={item.name}
                              width={40}
                              height={40}
                              className="img-fluid"
                            />
                            <span>{item.name}</span>
                          </div>
                        </>
                      ))}

                    {/* <div className='col-12 p-3' style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="material-symbols-outlined">light_mode</span>

                                            <span>Day & night</span>
                                        </div>
                                        <div className='col-12 p-3' style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="material-symbols-outlined">
                                                calendar_month
                                            </span>
                                            <span>Twice a day</span>
                                        </div>
                                        <div className='col-12 p-3' style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="material-symbols-outlined">
                                                hub
                                            </span>
                                            <span>Dropper</span>
                                        </div>
                                        <div className='col-12 p-3' style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="material-symbols-outlined">
                                                all_match
                                            </span>
                                            <span>All skin types</span>
                                        </div>
                                        <div className='col-12 p-3' style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="material-symbols-outlined">
                                                water_drop
                                            </span>
                                            <span>serum</span>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      {/* <div className='prodiv' style={{maxWidth:"30%",height:"auto"}}>
    <img src={productinfo[0].image} className='img-fluid prodimg' alt='products1'/>
   </div> */}
    </>
  );
}
export default ProductCard1;
