import React, { useEffect, useState } from 'react'
import Layout from '../Component/Layout'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import blogbg from './blog-bg.avif'
import moment from 'moment';

function BlogDetails() {
  const [blogs, setBlogs] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { slug } = useParams();
  const getBlogs = () => {
    axios
      .get(`${BASE_URL}getblogsbyslug/${slug}`,
        {
          headers: {
            "Content-Type": "Application/json",
            "version": "1.0.0",
          }
        }).then((response) => {
          if (response.data.success == false) { console.log(response.data.message) }
          else {
            setBlogs(response.data.data);
            console.log("response.data.data blog details:", response.data.data);

          }

        }).catch((error) => {
          console.log(error);
        })
  }
  useEffect(() => {
    getBlogs()
  }, [])
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <>
      <Layout>

        <div className="columns posts" >
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", backgroundImage: `url(${blogbg})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "200px", backgroundAttachment: "fixed"
          }} className='' >
            <h3 style={{ fontSize: "40px", color: "#fff" }}>Blogs</h3>
          </div>
          <div className='container mt-5'>
            <div className='text-black ' style={{ fontWeight: "500", fontSize: "18px" }}> Published on :&nbsp;&nbsp;{moment(blogs.createdAt).format('LL')}</div>
            <div className='row'>
            <div className='col-12 '>
                <img src={blogs.image} className='img-fluid' style={{width:"100%",height:"350px",objectFit:"cover"}}/>
               
              </div>
              <div className='col-12' style={{lineHeight:"2",wordSpacing:"2px",marginTop:"20px"}}>
              <div className='text-black mt-3' style={{ fontWeight: "500", fontSize: "24px" }}> {blogs.title}</div>
                {renderHTML(blogs.description)}
              </div>
              {
                  blogs.writtenBy&&
                <div className='text-black' style={{ fontWeight: "500", fontSize: "19px" }}>Written By &nbsp;:{blogs.writtenBy}</div>
                }
            </div>
           
          </div>
        </div>
      </Layout>

    </>
  )
}

export default BlogDetails