import React from 'react'
import NavMenu from './NavMenu'
import Footer from './Footer'
// import NavMenucopysubmenuchildmenu from './NavMenucopysubmenu&childmenu'

export default function Layout({children}) {
  return (
<>
<NavMenu/>
{/* <NavMenucopysubmenuchildmenu/> */}
{children}
<Footer/>
</>
  )
}
