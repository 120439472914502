import React from 'react'
import { useParams } from 'react-router-dom'

export default function Breadcrumbc() {
    const {slug}=useParams();
    console.log(slug);

    return (
        <>
            <div className='breadcrumbdiv ps-3 p-4 d-flex  align-items-center d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block' >
                <span>HOME</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span class="material-symbols-outlined" style={{fontSize:"17px"}}>
                    arrow_right
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>{slug}</span>
                
            </div>
        </>
    )
}
