import React, { useEffect, useState } from 'react'
import Layout from '../Component/Layout'
import FilterNav from '../Component/FilterNav'
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import './ordertable.css'


export default function Orders() {
  const navigate = useNavigate();



  const BASE_URL=process.env.REACT_APP_BASE_URL;
  const [values, setValues] = useState([]);
    useEffect(() => {
      let token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
      getAllOrders();
    }, []);
  
    const getAllOrders = () => {
      let token = localStorage.getItem("token");
  
      axios
        .post(
         `${process.env.REACT_APP_BASE_URL}getAllOrders`,
          {},
  
          {
            headers: {
              // version: "1.0.0",
              "x-access-token": ` ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.success) {
            // toast.error(response.data.message);
            setValues(response.data.data);
            console.log("key=======>",response.data.data);
          }
          // console.log("response =>", response.data.success);
        })
        .catch(function (error) {
          console.log(error);
          toast.error(error);
        });
    };

 


   
  return (
    <>
    <div>
        <Layout>
            <div >


            <FilterNav />
            {/* <button onClick={()=>getAllOrders()}>click to call api</button> */}
            <div className='container pb-5'>
            <table className="orderTable">
  <thead className="orderTablehead">
    <tr className="orderTr">
    <th className="orderTh"><label>S no.</label></th>

      <th className="orderTh"><label>OrderId</label></th>
      <th className="orderTh"><label>Name</label></th>
      {/* <th className="orderTh"><label>Mobile no.</label></th> */}
      <th className="orderTh"><label>Address 1</label></th>
      {/* <th className="orderTh"><label>Address 2</label></th> */}
      {/* <th className="orderTh"><label>State</label></th> */}
      {/* <th className="orderTh"><label>City</label></th> */}
      <th className="orderTh"><label>Total Amount</label></th>
      <th className="orderTh"><label>Status</label></th>
      <th className="orderTh"><label>Action</label></th>
    </tr>
  </thead>
  <tbody className>
    {
        values.map((item, index) =>(
            <>
              <tr className="orderTr" key={index}>
      <td className="ordertTd" data-label="S no.">{index+1}</td>

      <td className="ordertTd" data-label="OrderId">{item.user_Order_ID}</td>
      <td className="ordertTd" data-label="Name">{item.customer_name}</td>
      {/* <td className="ordertTd" data-label="Details">{item.customer_mobile_no}</td> */}
      <td className="ordertTd" data-label="Address">{item.customer_address1}</td>
      {/* <td className="ordertTd" data-label="Details">{item.customer_address2}</td> */}
      {/* <td className="ordertTd" data-label="Details">{item.state}</td> */}
      {/* <td className="ordertTd" data-label="Details">{item.city}</td> */}
      <td className="ordertTd" data-label="Amount">{item.amount} Rs/-</td>
      <td className="ordertTd" data-label="Status">{item.status}</td>
      <td className="ordertTd" data-label="Action">
      <button className="btn btn-primary btn-sm m-1" style={{backgroundColor:"#364255",fontSize:"15px",float:"right"}}>
                <i className="fa fa-eye" onClick={()=>navigate("/products-info",{state:item})}></i>
              </button>
              &nbsp;
              {/* <button className="btn btn-danger btn-sm m-1" style={{fontSize:"15px"}}>
                <i className="fa fa-print"></i>
              </button> */}
              &nbsp;
          
        
        </td>
      {/* <td className="ordertTd" data-label="Payment"><button className="btn-invoice">Make Payment </button></td> */}

    </tr>
            </>
        ))
    }
  
    {/* <tr className="orderTr">
      <td className="ordertTd" data-label="Invoice">B11289678234</td>
      <td className="ordertTd" data-label="Details">With Payment of Tax</td>
      <td className="ordertTd" data-label="Due Date">July 13, 2018</td>
      <td className="ordertTd" data-label="Amount">$56.00</td>
      <td className="ordertTd" data-label="Payment"><button className="btn-invoice">Make Payment</button></td>
    </tr>
    <tr className="orderTr">
      <td className="ordertTd" data-label="Invoice">C11289678234</td>
      <td className="ordertTd" data-label="Details">With Payment of Tax</td>
      <td className="ordertTd" data-label="Due Date">June 13, 2018</td>
      <td className="ordertTd" data-label="Amount">$46.00</td>
      <td className="ordertTd" data-label="Payment"><button className="btn-invoice">Make Payment</button></td>
    </tr>
    <tr className="orderTr">
      <td className="ordertTd" data-label="Invoice">D11289678234</td>
      <td className="ordertTd" data-label="Details">With Payment of Tax</td>
      <td className="ordertTd" data-label="Due Date">May 13, 2018</td>
      <td className="ordertTd" data-label="Amount">$28.00</td>
      <td className="ordertTd" data-label="Payment"><button className="btn-invoice">Make Payment</button></td>
    </tr> */}
  </tbody>
</table>
{
  values.length>0?"":<div className='d-flex justify-content-center align-items-center ' style={{height:"300px"}}>
  <div> No Record Found</div>
</div>
}

            </div>


            </div>
        </Layout>



      

    </div>

  

</>

  )
}
