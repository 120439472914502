import { Button, Offcanvas } from 'bootstrap';
import React, { useContext, useEffect, useState } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';

import PerfectScrollbar from 'react-perfect-scrollbar'
// import Login from '../Pages/Login';
import { webinfo } from "../App";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Mobilemenu from './Mobilemenu';
import './NavMenu.css'
import { CartContext } from "../App"



function NavMenu() {
  const navigate = useNavigate();


  const state = useContext(CartContext);

  console.log("state=========cartlen===============>", state);
  useEffect(() => {
    state?.loadCartList();
  })

  const [allcategorynsubcategor, setAllcategorynsubcategor] = useState([]);
  const [show, setShow] = useState(false);
  const [showmenu, setShowmenu] = useState(false);
  const websiteInfo = useContext(webinfo);
  const [allCategories, setAllCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [allSubCategories, setAllSubCategories] = useState({});
  const [slug, setSlug] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // console.log("navwebsiteinfo",websiteInfo);


  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getAllcategories = () => {
    axios
      .get(`${BASE_URL}getAllCategory`,
        {
          headers: {
            "Content-Type": "application/json",
            "version": "1.0.0"
          }
        }).then((response) => {
          if (response.data.success == false) {
            // console.log()
          } else {
            setAllCategories(response.data.data);
            console.log("categories", response.data.data);
          }
        })
  }
  const getAllsubCategories = () => {
    axios
      .get(`${BASE_URL}getSubCategory/${categoryId}`, {
        headers: {
          "Content-Type": "Application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success == false) {

        } else {
          setAllSubCategories(response.data.data);
        }
      })
  }
  useEffect(() => {
    getAllcategories();
  }, [])

  // useEffect(()=>{
  //   const arr=[];
  // if(allCategories>0&&allSubCategories>0)
  // {
  //   allCategories.map((item,index)=>{
  //     let obj={}
  //     allSubCategories.filter((s,i)=>{
  //       if(s.categoryId==item._id)
  //       {
  //         obj["category"]=item
  //         obj["subcategory"]=s;
  //       }

  //     }

  //     )
  //     arr.push(obj);
  // })
  // setAllcategorynsubcategor(arr);
  // }



  // },[allCategories,allSubCategories])
  // useEffect(()=>{
  // console.log("recent",allcategorynsubcategor);
  // },[])

  return (
    <>
      <div>
        <nav className='w-100' style={{ boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px", paddingBottom: "0" }}>
          <div className="wrapper">
            <div className="logo"><a href="#"><img src={websiteInfo.logo} alt='logo' onClick={() => navigate("/")} className='navlogo' /></a></div>
            <input type="radio" name="slider" id="menu-btn" onClick={() => setShowmenu(true)} />
            <input type="radio" name="slider" id="close-btn" />
            <ul className="nav-links">
              <label htmlFor="close-btn" className="btn close-btn">
                <span className="material-symbols-outlined">
                  close
                </span>
              </label>
              <li><Link to={`/`} className='dottedText' style={{ textTransform: "uppercase" }}>Home</Link></li>

              <li><Link to={`/About-Us`} className='dottedText' style={{ textTransform: "uppercase" }}>About Us</Link></li>

              {
                allCategories.length > 0 && allCategories.map((item, index) => (
                  <>
                    <li key={index}>
                      <a href="#" className="desktop-item dottedText">{item.name}</a>
                      <input type="checkbox" id="showDrop" />
                      <label htmlFor="showDrop" className="mobile-item">{item.name}</label>
                      <ul className="drop-menu">
                        {
                          item.subCategory.length > 0 && item.subCategory.map((item1, index1) => (
                            <>
                            {
                              item1.status=='Active'&&
                              <>
                              <li key={index1}><Link to={`/child-categories/${item1.slug}`} style={{fontSize:"15px"}}>{item1.name}</Link></li>
                              </>
                            }
                              
                            </>
                          ))
                        }

                        {/* <li><a href="#">News </a></li>
                  <li><a href="#">Awards</a></li>
                  <li><a href="#">Sesderma TV</a></li>
                  <li><a href="#">Work with us</a></li> */}

                      </ul>
                    </li>
                  </>
                ))
              }
              <li><Link to={`/blogs`} className='dottedText' style={{ textTransform: "uppercase" }}>Blogs</Link></li>
              <li><Link to={`/contact-us`} className='dottedText' style={{ textTransform: "uppercase" }}>Contact Us</Link></li>



              {/* {
                allCategories.length > 0 && allCategories.map((item, index) =>
                (

                  <li key={index}>
                    <Link to={`/category/${item?.slug}`} className="desktop-item dottedText"  >{item?.name}</Link>
                    <input type="checkbox" id="showMega" />
                    <label htmlFor="showMega" className="mobile-item " >{item.name}</label>


                    <div className="mega-box">
                      <div className="contentdrop">
                        <div className='row w-100'>
                          <div className='col-md-3'>

                            <ul className='subLinkul' style={{ height: "200px", padding:"20px" }}>
                            <PerfectScrollbar>
                              {
                                item.subCategory.length>0?item.subCategory.map((item1, index1)=>(
                                  <>
                             <li key={index1} ><Link to={`/subcategoryview/${item?.slug}/${item1?.slug}`} id="subCategoryLink">{item1.name}</Link></li>
                                  </>)):"no subCategory available"
                              }
                             
                            
                              
                              </PerfectScrollbar>

                            </ul>
                          </div>

                          <div className="col-md-4" >
                            <img src={item.icon} alt={item.name} className='img-fluid'/>
                          </div>

                          <div className="col-md-5 d-flex justify-content-center align-items-center p-3">
                            <div>
                            <header style={{ fontSize: "35px", textAlign: "center", textTransform: "uppercase" }} className='dottedText' >{item.heading1}</header>
                            <p style={{ lineHeight: "1.3", textAlign: "center",padding:"5px 18px " }} className=''>{item.description}</p>
                            <div className='d-flex justify-content-center'>
                              <button style={{ padding: "8px 14px", backgroundColor: "#313d4e", color: "#fff" }} >DISCOVER MORE</button>

                            </div>
                            </div>
                          
                          </div>

                        </div>

                      </div>
                    </div>
                  </li>
                ))
              } */}


              {/* <li>
                <a href="#" className="desktop-item dottedText" >Body care</a>
                <input type="checkbox" id="showMega" />
                <label htmlFor="showMega" className="mobile-item">Body care</label>
                <div className="mega-box">
                  <div className="contentdrop">

                    <div className="row">
                      <header>Design Services</header>
                      <ul className="mega-links">

                        <li><a href="#">Graphics</a></li>
                        <li><a href="#">Vectors</a></li>
                        <li><a href="#">Business cards</a></li>
                        <li><a href="#">Custom logo</a></li>
                      </ul>
                    </div>
                    <div className="row" style={{ width: "40%" }}>
                      <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                    </div>

                    <div className="row">
                      <header style={{ fontSize: "45px" }}>SOLAR PROTECTION</header>
                      Discover all the promotions of this season. We prepare for you the perfect routines for each of your concerns.

                      <button style={{ padding: "20px", backgroundColor: "#313d4e" }}></button>
                    </div>

                  </div>
                </div>
              </li> */}


              {/* <li><a href="#">Body care</a></li> */}

              {/* <li>
                <a href="#" className="desktop-item dottedText">Sunscreens</a>
                <input type="checkbox" id="showMega" />
                <label htmlFor="showMega" className="mobile-item">Mega Menu</label>
                <div className="mega-box">
                  <div className="contentdrop">

                    <div className="row">
                      <header>Design Services</header>
                      <ul className="mega-links">

                        <li><a href="#">Graphics</a></li>
                        <li><a href="#">Vectors</a></li>
                        <li><a href="#">Business cards</a></li>
                        <li><a href="#">Custom logo</a></li>
                      </ul>
                    </div>
                    <div className="row" style={{ width: "40%" }}>
                      <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                    </div>

                    <div className="row">
                      <header style={{ fontSize: "45px" }}>SOLAR PROTECTION</header>
                      Discover all the promotions of this season. We prepare for you the perfect routines for each of your concerns.

                      <button style={{ padding: "20px", backgroundColor: "#313d4e" }}></button>
                    </div>

                  </div>
                </div>
              </li> */}
              {/* <li>
                <a href="#" className="desktop-item dottedText">Packs</a>
                <input type="checkbox" id="showMega" />
                <label htmlFor="showMega" className="mobile-item">Packs</label>
                <div className="mega-box">
                  <div className="contentdrop">

                    <div className="row">
                      <header>Design Services</header>
                      <ul className="mega-links">

                        <li><a href="#">Graphics</a></li>
                        <li><a href="#">Vectors</a></li>
                        <li><a href="#">Business cards</a></li>
                        <li><a href="#">Custom logo</a></li>
                      </ul>
                    </div>
                    <div className="row" style={{ width: "40%" }}>
                      <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                    </div>
                  
                    <div className="row">
                      <header style={{ fontSize: "45px" }}>SOLAR PROTECTION</header>
                      Discover all the promotions of this season. We prepare for you the perfect routines for each of your concerns.

                      <button style={{ padding: "20px", backgroundColor: "#313d4e" }}></button>
                    </div>

                  </div>
                </div>
              </li> */}
              {/* <li><a href="#" className='dottedText' style={{ textTransform: "uppercase" }}>Blog</a></li>
              <li>
                <a href="#" className="desktop-item dottedText">Know us</a>
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">Know us</label>
                <ul className="drop-menu">

                  <li><a href="#">Sesderma universe</a></li>
                  <li><a href="#">News </a></li>
                  <li><a href="#">Awards</a></li>
                  <li><a href="#">Sesderma TV</a></li>
                  <li><a href="#">Work with us</a></li>

                </ul>
              </li> */}
            </ul>
            <div className='d-flex align-items-center'>
              <span className="material-symbols-outlined navsearchI" onClick={() => navigate("/product-search")} >
                search
              </span>&nbsp;&nbsp;
              {
                localStorage.getItem('token')&&
                <>
                  <span className="material-symbols-outlined navuserI" onClick={() => navigate('/myprofile')}>
                person
              </span>&nbsp;&nbsp;
                </>
              }
            
              <span className="material-symbols-outlined  navcartI" onClick={() => navigate('/addtocart')}>
                shopping_cart
              </span>
              <div className='cartcountdiv'
              //          style={{position: "relative",
              // width: "23px",
              // height: "20px",
              // color: "#fff"}}

              ><span className='cartcountspan'

              // style={{    position: "relative",
              //   top: "-89px",
              //   left: "98px",
              //   backgroundColor: "#000",
              //   color: "#fff",
              //   borderRadius: "50%" , padding:"3px "}}

              >{state?.cartlen}</span></div>
            </div>
            <label htmlFor="menu-btn" className="btn menu-btn "><span className="material-symbols-outlined navmenuI">
              menu
            </span></label>
          </div>
        </nav>

      </div>






      {/* <Button variant="primary" onClick={()=>setShowmenu(true)}>
        Launch
      </Button> */}


      <Mobilemenu show={showmenu} onHide={() => setShowmenu(false)} />
      {/* <Login show={show} onHide={() => setShow(false)} /> */}

    </>
  )
}
export default NavMenu;
