import React, { useContext, useState } from 'react'
import Layout from '../Component/Layout'
import axios from 'axios'
import { toast } from 'react-toastify';
import { ValidMobileNumber, ValidEmail } from "../Functions/Validation";
import contactimage from "./contact-us.jpg"
import { webinfo } from "../App";

function Contactus() {
    const websiteInfo = useContext(webinfo);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [name, setName] = useState("");
    const [mobile_no, setMobile_no] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");



    const submit = () => {
        if (!name) {
            toast.error("Name is required");
        }
        if (!ValidMobileNumber(mobile_no)) {
            toast.error("Mobile Number is required ");
        }
        if (!ValidEmail(email)) {
            toast.error("Please enter a valid email");
        }
        if (!subject) {
            toast.error("Subject is required");
        }

        axios
            .post(`${BASE_URL}addContact`, {
                name: name,
                email: email,
                mobile_no: mobile_no,
                subject: subject,
                message: message
            }, {
                headers: {
                    "Content-Type": "Application/json",
                    "version": "1.0.0",
                }
            }).then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message)
                } 
            }).catch((err) => {
                console.log(err.message);
            })
    }
    return (
        <>
            <Layout>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center", backgroundImage: `url(${contactimage})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "300px", backgroundAttachment: "fixed"
                }} className='' >
                    <h3 style={{ fontSize: "40px", color: "#000" }}>Contact Us</h3>
                </div>




              




                <div className='container-fluid mt-5 mb-5'>




                    <div className='m-md-5'  >

                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 col-md-12 col-lg-10 col-xl-10 col-xxl-12 co-sm-12 col-xs-12 '>
                                <div className='row'>
                                    <div className='col-12 col-md-4 mb-3 '>
                                        <div className='p-5' style={{ backgroundColor: "#efefef", display: "flex", justifyContent: 'center', alignItems: "center", height: "200px" }}>
                                            <div >
                                                <p className='text-center'>
                                                    <span class="material-symbols-outlined" style={{ fontSize: "6rem",color:"#252f3b" }}>
                                                        drafts
                                                    </span>
                                                </p>
                                                <p>{websiteInfo.address}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4 mb-3'>
                                        <div className='p-5' style={{ backgroundColor: "#efefef", display: "flex", justifyContent: 'center', alignItems: "center", height: "200px" }}>
                                            <div >
                                                <p className='text-center'>
                                                    <span class="material-symbols-outlined" style={{ fontSize: "6rem",color:"#252f3b" }}>
                                                        notification_multiple
                                                    </span>
                                                </p>
                                                <a href={`mailto:${websiteInfo.email}`}>{websiteInfo.email}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4 mb-3 '>
                                        <div className='p-5' style={{ backgroundColor: "#efefef", display: "flex", justifyContent: 'center', alignItems: "center", height: "200px" }}>
                                            <div >
                                                <p className='text-center'>
                                                    <span class="material-symbols-outlined" style={{ fontSize: "6rem",color:"#252f3b" }}>
                                                        call
                                                    </span>
                                                </p>
                                                <a href={`tel:+91${websiteInfo.phone_no}`}>+91 {websiteInfo.phone_no}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='row  d-flex justify-content-center mt-5' >
                            <div className='col-1 col-md-2'></div>
                            <div className='col-md-7 p-4 pb-5' >

                                <div className='px-5 ' >
                                    <h1 className='pb-2 ' style={{ textTransform: "uppercase", color: "#252f3b" }}>Contact Us</h1>
                                    <div className='mb-3' style={{ height: "2px", backgroundColor: "#252f3b", width: "100px" }}></div>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="mb-3" controlId="formBasicEmail">
                                                <label className='py-3'>Fullname</label>
                                                <input type="text" placeholder="Enter name"
                                                    onChange={
                                                        (e) => setName(e.target.value)
                                                    }
                                                    name="name"
                                                    value={name}
                                                    className='checkoInput ' style={{ backgroundColor: "#efefef" }} />

                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className="mb-3" controlId="formBasicEmail">
                                                <label className='py-3'>Mobile number</label>
                                                <input type="number" placeholder="Enter mobile number"
                                                    onChange={(e) => setMobile_no(e.target.value)}
                                                    name="mobile_no"
                                                    value={mobile_no}
                                                    className='checkoInput' style={{ backgroundColor: "#efefef" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3" controlId="formBasicEmail">
                                        <label className='py-3'>Email Id</label>
                                        <input type="email" placeholder="Enter email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            name="email"
                                            // disabled
                                            value={email}
                                            className='checkoInput ' style={{ backgroundColor: "#efefef" }} />

                                    </div>
                                    <div className="mb-3" controlId="formBasicEmail">
                                        <label className='py-3'>Subject</label>
                                        <input type="text" placeholder="Enter subject"
                                            onChange={(e) => setSubject(e.target.value)}
                                            name="subject"
                                            // disabled
                                            value={subject}
                                            className='checkoInput ' style={{ backgroundColor: "#efefef" }} />

                                    </div>
                                    <div className="mb-3" controlId="formBasicEmail">
                                        <label className='py-3'>Message</label>
                                        <input type="text" placeholder="Enter subject"
                                            onChange={(e) => setMessage(e.target.value)}
                                            name="subject"
                                            // disabled
                                            value={message}
                                            className='checkoInput' style={{ backgroundColor: "#efefef" }} />
                                    </div>
                                    <br />
                                    <br />
                                    <div className="mb-3 mt-3" controlId="formBasicEmail">
                                        <button className='btn loginbtn' onClick={() => submit()}><h3>Submit</h3></button>
                                    </div>
                                </div>

                            </div>

                            <div className='col-1 col-md-2'></div>














                          


                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Contactus