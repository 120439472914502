import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ValidEmail } from "../Functions/Validation";

function Homenews() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState();
  const [active, setActive] = useState(false);
  const AddSubscriber = () => {
    if (!ValidEmail(email)) {
      toast.error("Kindly provide valid email");
      setEmail("");
      return;
    }
    if (active == false) {
      toast.error("check if you read our newsletter terms and condition");
      setEmail("");
      return;
    }

    console.log(active);
    axios
      .post(
        `${BASE_URL}addSubscriber`,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            version: "1.0.0",
          },
        }
      )
      .then((res) => {
        if (res.data.success == false) {
          toast.error(res.data.message);

          setEmail("");
        } else {
          toast.success(res.data.message);
          setEmail("");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      <div
        className="newsdiv  p-sm-3 d-flex justify-content-center "
        style={{
          backgroundColor: "#2e3a49",
          color: "#fff",
          minHeight: "450px",
        }}
      >
        <div className="row w-100 d-flex justify-content-center align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-0 col-md-1"></div>
              <div className="col">
                <div className="row">
                  <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="getNewsH">Be the first to get the news!</p>
                    <p style={{ fontSize: "14px" }}>
                      Subscribe to the Skin Science Newsletter and don’t miss
                      out on the latest dermocosmetics, exclusive offers and
                      tips for a healthy and beautiful skin
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center align-items-center">
                    <div className="row w-100">
                      <div className="col-8">
                        <input
                          type="email"
                          className="p-md-4 p-3"
                          style={{
                            width: "100%",
                            borderRadius: "0",
                            outline: "none",
                            background: "transparent",
                            border: "1px solid #fff",
                            color: "#fff",
                            fontSize: "15px",
                          }}
                          defaultValue="Email"
                          placeholder="Enter Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                      <div className="col-4">
                        <button
                          className="loginbtn fs-md-1 fs-2 "
                          style={{ float: "right", padding: "10px" }}
                          onClick={() => AddSubscriber()}
                        >
                          {" "}
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            width: "20px",
                            height: "15px",
                            outline: "none",
                            background: "transparent",
                            border: "1px solid #fff",
                          }}
                          onClick={(e) => setActive(e.target.checked)}
                        />
                        &nbsp;&nbsp;
                        <span>I have read the terms & condition</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-0 col-md-1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Homenews;
