import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import { useParams } from "react-router-dom";
import NavMenu from "../Component/NavMenu";
import Footer from "../Component/Footer";
import axios from "axios";
import Productcard from "../Component/Productcard";
import FilterNav from "../Component/FilterNav";
import ProductCard1 from "../Component/ProductCard1";
const slugToTitle = (slug) => {
  // console.log("slug ===>", slug.slug);
  let slugCheck = slug.slug;
  return slugCheck
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export default function HomecategoryView() {
  const slug = useParams();

  // const [categorySlug , setCategorySlug]=useState("");
  const [products, setProducts] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const Products = () => {
    axios
      .post(
        `${BASE_URL}ProductsByHomeCategorySlug`,
        { homeCategorySlug: slug },
        {
          headers: {
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
          setProducts([]);
        } else {
          setProducts(response.data.data);
          console.log(" category view slug", response.data.data[0].slug);
        }
      });
  };
  useEffect(() => {
    // AllCategory();
    Products();
    // console.log("slug",slug);
  }, [slug]);

  return (
    <>
      <NavMenu />
      <div
        className="cateview"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3 style={{ fontSize: "40px", color: "#fff" }}>{slugToTitle(slug)}</h3>
      </div>
      <div
        className="container-fluid pt-5 pb-5"
        style={{ backgroundColor: "#fff", minHeight: "1000px" }}
      >
        <div className=" ">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="row">
                {products.length > 0 &&
                  products.map((item, index) => (
                    <div
                      className="col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center"
                      key={index}
                      style={{ marginTop: "15px" }}
                    >
                      <ProductCard1 product={item} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* product card end  */}
      <Footer />

      {/* </Layout> */}
    </>
  );
}
