import React from 'react'
import "../App.css";
export default function FilterNavCopy() {
  return (
    <>
<div>
<nav style={{zIndex:"1",width:"100%!important"}}>
        <label htmlFor="toggleMainNav" className="hamburgermenu">

          Filter  
          <span className="material-symbols-outlined">
            expand_more
          </span>
        </label>
        <input type="checkbox" id="toggleMainNav" className="check-toggle" />
        {/* <label for="toggleMainNav" class="menu-overlay"></label> */}
        <div className="main-nav-holder">
          <ul className="main-nav">
            <li className="close-nav">
              <label htmlFor="toggleMainNav"></label>
            </li>
         
           
            <li className="has-submenu">
              <input type="checkbox" id="labelAntioxidants" className="check-toggle" />
              <label htmlFor="labelAntioxidants">Antioxidants</label>

              <i className="menu-caret"></i>
              <ul className="submenu">
                <li>
                  <a href="#">T-shirt</a>
                </li>
                <li>
                  <a href="#">Polo</a>
                </li>
                <li>
                  <a href="#">Jeans</a>
                </li>
                <li>
                  <a href="#">Shirt</a>
                </li>
              </ul>
            </li>
        
         
            <li className="has-submenu">
              <input type="checkbox" id="labelMen" className="check-toggle" />
              <label htmlFor="labelMen">Men</label>

              <i className="menu-caret"></i>
              <ul className="submenu">
                <li>
                  <a href="#">T-shirt</a>
                </li>
                <li>
                  <a href="#">Polo</a>
                </li>
                <li>
                  <a href="#">Jeans</a>
                </li>
                <li>
                  <a href="#">Shirt</a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <input type="checkbox" id="labelWomen" className="check-toggle" />
              <label htmlFor="labelWomen">Women</label>

              <i className="menu-caret"></i>
              <ul className="submenu">
                <li>
                  <a href="#">Tops</a>
                </li>
                <li>
                  <a href="#">Leggins</a>
                </li>
                <li>
                  <a href="#">Ornaments</a>
                </li>
                <li>
                  <a href="#">Makeups</a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <input type="checkbox" id="labelbeautyproducts" className="check-toggle" />
              <label htmlFor="labelbeautyproducts">Beauty products</label>

              <i className="menu-caret"></i>
              <ul className="submenu">
                <li>
                  <a href="#">Tops</a>
                </li>
                <li>
                  <a href="#">Leggins</a>
                </li>
                <li>
                  <a href="#">Ornaments</a>
                </li>
                <li>
                  <a href="#">Makeups</a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <input type="checkbox" id="labelaging" className="check-toggle" />
              <label htmlFor="labelaging">Anti-aging</label>

              <i className="menu-caret"></i>
              <ul className="submenu">
                <li>
                  <a href="#">Tops</a>
                </li>
                <li>
                  <a href="#">Leggins</a>
                </li>
                <li>
                  <a href="#">Ornaments</a>
                </li>
                <li>
                  <a href="#">Makeups</a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <input type="checkbox" id="labelBeauty" className="check-toggle" />
              <label htmlFor="labelBeauty">beauty</label>

              <i className="menu-caret"></i>
              <ul className="submenu">
                <li>
                  <a href="#">Tops</a>
                </li>
                <li>
                  <a href="#">Leggins</a>
                </li>
                <li>
                  <a href="#">Ornaments</a>
                </li>
                <li>
                  <a href="#">Makeups</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>


      </nav>
</div>
     
    </>
  )
}
