import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
function Collections() {
    const [testimonial, setTestimonial] = useState([]);
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const getAllTestimonial = () => {
        axios
            .post(`${BASE_URL}getAllTestimonial/${1}`,
                {
                    headers: {
                        "Content-Type": "Application/json",
                        "version": "1.0.0"
                    }
                }).then((response) => {
                    if (response.data.success == false) {

                    } else {
                        setTestimonial(response.data.data);
                        console.log("Testimonial", response.data.data);
                    }
                }).catch((err) => {
                    console.log(err.message);
                })
    }

    const renderHTML = (rawHTML) =>
        React.createElement("div", {
            dangerouslySetInnerHTML: { __html: rawHTML },
        });
    useEffect(() => {
        getAllTestimonial();
    }, [])
    return (
        <>
           {
            testimonial.length>0&&
            <div style={{
                backgroundImage: `url(https://img.freepik.com/premium-photo/golden-leaves-gray-pastel-background_512343-66.jpg?size=626&ext=jpg&ga=GA1.1.587528429.1701858693&semt=ais)`, backgroundRepeat: "no-repeat", backgroundPosition: "right", backgroundSize: "cover", height: "370px", backgroundAttachment: "fixed"
            }} >
                <Carousel
                    autoPlay
                    showThumbs={false}
                    className='d-block d-lg-block d-xl-block d-xxl-block'

                >
                    {
                        testimonial.length ? testimonial.map((item, index) => (
                            <>
                                <div style={{ height: "500px!important" }} key={index}>
                                    <div className='row'>
                                        <div className='col-0 col-md-2'>

                                        </div>
                                        <div className='col'>
                                            <div className='d-flex justify-content-start align-items-center' style={{ minHeight: "325px" }}>
                                                <div className='container ms-md-5 me-md-5' style={{ textAlign: "start" }} >
                                                    <span><img src={item.image} className='img-fluid' style={{ borderRadius: "50%", width: "50px", height: "50px" }} /></span> <span className='mb-3' style={{
                                                        fontSize: "2.5rem", paddingTop: "55px", color: "#000", overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis"
                                                    }}>{item.name}</span>
                                                    <br />
                                                    <span style={{
                                                        position: "relative",
                                                        top: "-12px",
                                                        left: "59px"
                                                    }}>

                                                        {Array.from({ length: item.rate }, (_, i) => i + 1).map((rate) => (
                                                            <>
                                                                <span className='fa fa-star' style={{ color: 'yellow' }}></span>

                                                            </>
                                                        ))}

                                                    </span>

                                                    <p style={{ color: "#000" }}>{item.description} </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-0 col-md-2'>

                                        </div>
                                    </div>

                                </div>
                            </>
                        )) : ""
                    }

            
                </Carousel>

            </div>
     
           }
                       



        </>
    )
}
export default Collections;