import React, { useEffect } from 'react'
import { useState } from 'react';
import IndianStates from '../data/IndianStates.json'
// import './myprofile.css'
import Layout from '../Component/Layout';
import FilterNav from '../Component/FilterNav';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


export default function Changepassword() {
  const navigate = useNavigate();
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  const submit = () => {
    let token = localStorage.getItem("token");
    if (!old_password) {
      toast.error("Old password Field Is Mandatory");
      return;
    }
    if (!new_password) {
      toast.error("New password Field Is Mandatory");
      return;
    }
    if (new_password != confirm_password) {
      toast.error("New Password is not matching to the Confirm Password");
      return;
    }

    const obj = {
      old_pass: old_password,
      new_pass: new_password
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/change-password`,
        obj,

        {
          headers: {
            // version: "1.0.0",
            "x-access-token": ` ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          localStorage.setItem("user", response.data.data);
          setConfirm_password("");
          setNew_password("");
          setOld_password("")
          // navigate("/change-password");
        }
        // console.log("response =>", response.data.success);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };




  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    // loadProfile();
  }, []);



  return (
    <>
      <Layout>
        <div className=''>
          <FilterNav />
          <div className='container mt-5 mb-5'>
            <div className=''  >

              <div className='row d-flex justify-content-center ' >
                {/* <div className='col-1 col-md-3'></div> */}
                <div className='col-md-7 p-4' style={{ backgroundColor: "#f2f2f2", boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                  <div className='p-5' >
                    <div className='row'>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='py-3'>Old password</label>
                          <input type="text" placeholder="Enter old password"
                            onChange={(e) => setOld_password(e.target.value)}
                            name="old_password"
                            value={old_password}
                            className='checkoInput' />
                        </div>
                      </div>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='py-3'>New password</label>
                          <input type="text" placeholder="Enter new password"
                            onChange={(e) => setNew_password(e.target.value)}
                            name="new_password"
                            value={new_password}
                            className='checkoInput' />

                        </div>
                      </div>
                    </div>
                    <div className="mb-3" controlId="formBasicEmail">
                      <label className='py-3'>Confirm password</label>
                      <input type="text" placeholder="confirm password"

                        name="confirm_password"
                        onChange={(e) => setConfirm_password(e.target.value)}
                        value={confirm_password}
                        className='checkoInput ' />

                    </div>


                    <br />
                    <br />
                    <br />


                    <div className="mb-3" controlId="formBasicEmail">
                      <button className='btn' style={{ width: "100%", padding: "10px", backgroundColor: "#364255", color: "#fff" }} onClick={() => submit()}><h3>Change password</h3></button>

                    </div>
                  </div>

                </div>
                {/* <div className='col-1 col-md-3'></div> */}



              </div>
            </div>
          </div>
        </div>





      </Layout>





    </>
  )
}
