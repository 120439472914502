import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Layout from '../Component/Layout';
import axios from 'axios';

function Aboutus() {
    // const slug = useParams();
    const [pageContent, setPageContent] = useState([]);
    useEffect(() => {
        // console.log("===========================================================>",slug)
      GetPages();
    }, []);
  
    let GetPages = () => {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}getPages`, {
          page_slug: "About-Us",
        })
        .then(function (response) {
          // handle success
          // if (mounted) {
          if (response.data.success) {
            console.log("page content======>",response.data.data);
            setPageContent(response.data.data);
          }
          // }
  
          // bindCategory();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    };
  
    const renderHTML = (rawHTML) =>
      React.createElement('div', {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });
  
  return (
    <>
    <Layout>

     <div  style={{ display: "flex",
    justifyContent: "center",
    alignItems: "center",}} className='cateview'>
<h3 style={{fontSize:"40px",color:"#fff"}}>{pageContent.name}</h3>
        </div>

        <div className='container py-5' >
        {renderHTML(pageContent.description)}
            
        </div>
    </Layout>

    </>
  )
}

export default Aboutus