import React, { useContext, useEffect, useState } from "react";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import { webinfo } from "../App";
import { categories } from "../App";
import { Link } from "react-router-dom";
import axios from "axios";
function Footer() {
  const websiteInfo = useContext(webinfo);
  const CategoryNames = useContext(categories);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [subcategory, setSubCategory] = useState();
  // console.log("footer category",CategoryNames.name);
  const d = new Date();
  // console.log("footer websiteInfo", websiteInfo);

  return (
    <>
      {/* <div className="container-fluid ">
        <footer
          className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5  border-top"
          style={{ float: "right", width: "100%" }}
        >
          <div className="col  col-md-3 mb-3 ps-2">
            <a
              href="/"
              className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none"
            >
              <img
                className="img-fluid"
                src={websiteInfo.logo}
                alt=""
                style={{ width: "70%" }}
              />
            </a>
            <p className="text-body-secondary">{websiteInfo.about_us}</p>
          </div>
      

          <div className="col col-md-3 mb-3">
            <h2>Category</h2>
            <ul className="nav flex-column">
              {CategoryNames.length > 0 &&
                CategoryNames.map((item, index) => (
                  <>
                    <li className="nav-item mb-2" key={index}>
                      <a href="#" className="nav-link p-0 text-body-secondary">
                        {item.name}
                      </a>
                    </li>
                  </>
                ))}
            </ul>
          </div>

          <div className="col col-md-3 mb-3">
            <h2>Useful Links</h2>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-body-secondary">
                  About Us
                </a>
                <a href="#" className="nav-link p-0 text-body-secondary">
                  Term & Condition
                </a>
                <a href="#" className="nav-link p-0 text-body-secondary">
                  Privacy Policy
                </a>
                <a href="#" className="nav-link p-0 text-body-secondary">
                  Return Policy
                </a>
              </li>
            </ul>
          </div>

        
          <div className="col col-md-3 mb-3">
            <h2>Contact Info</h2>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href={`tel:+91${websiteInfo.phone_no}`}
                  className="nav-link p-0 text-body-secondary"
                >
                  <i className="fa fa-phone"></i>&nbsp;{websiteInfo.phone_no}
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href={`mailto:${websiteInfo.email}`}
                  className="nav-link p-0 text-body-secondary lowercase-text"
                >
                  <i className="fa fa-envelope"></i>&nbsp;{websiteInfo.email}
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>

      <div className="container">
        <footer
          className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top"
          style={{ float: "left", width: "100%" }}
        >
          <div className="col-md-3 d-flex align-items-center">
            <a
              href="/"
              className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
            >
              <div className="d-flex gap-3">
                <Link to={websiteInfo.facebook_url}>
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-facebook"
                    aria-hidden="true"
                  ></i>
                </Link>
                <Link to={websiteInfo.twitter_url}>
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-twitter"
                    aria-hidden="true"
                  ></i>
                </Link>
                <Link to={websiteInfo.instgram_url}>
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-instagram"
                    aria-hidden="true"
                  ></i>
                </Link>
                <Link to={websiteInfo.youtube_url}>
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-youtube-play"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </a>
          </div>
          <div className=" col-md-12 col-lg-6 d-flex align-items-center justify-content-center">
            <div>
              {" "}
              © {d.getFullYear()} Skin Science Laboratory Design By{" "}
              <a href="https://logicwebplus.com/">
                <b>Logic Web Plus</b>
              </a>
              .
            </div>
          </div>
          <ul className="nav col-md-3 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              <a className="text-body-secondary" href="#">
                <i className="fa fa-cc-visa" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-3">
              <a className="text-body-secondary" href="#">
                <i className="fa fa-cc-mastercard" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-3">
              <a className="text-body-secondary" href="#">
                <i className="fa fa-paypal" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </footer>
      </div> */}

      <footer id="footer">
        <div id="footer-top" className="container">
          <div className="row"> <div className="block col-sm-3">
            <h3>About Us</h3>

            <ul className="footer-links">
              <div className="menu-footer-about-container">
                <ul id="menu-footer-about" className="menu">
                  <li id="menu-item-696" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-696"><Link to="/About-Us">About Us</Link>
                  </li>
                  {/* <li id="menu-item-1297" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1297"><Link to="https://www.ethicare.in/wp-content/uploads/Ethicare-Compny-profile.pdf">Download Company Brochures</Link>
                  </li> */}
                  <li id="menu-item-1300" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1300"><Link to="#">Terms &amp; Conditions</Link></li><li id="menu-item-1299" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1299"><Link to="#">Privacy Policy</Link>
                  </li>

                  <li id="menu-item-1298" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1298">
                    <Link to="#">Payment Terms</Link>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
            <div className="block col-sm-3">
              <h3>Our Products</h3>
              <ul className="footer-links">
                <div className="menu-footer-product-container">
                  <ul id="menu-footer-product" className="menu">

                    <li id="menu-item-699" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-699"><Link to="#">Product Range</Link>
                    </li>

                    <li id="menu-item-700" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-700"><Link to="#">Professional Range</Link></li>

                    <li id="menu-item-1302" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1302"><Link to="#">Image Skin Care</Link>
                    </li>

                    {/* <li id="menu-item-1301" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1301">
                      <Link to="#">Download E-Brochures</Link>
                    </li> */}

                  </ul>

                </div>
              </ul>
            </div>
            <div className="block col-sm-3">
              
              <h3>Useful Links</h3>
              
              <ul className="footer-links">
              <div className="menu-useful-links-container">
                <ul id="menu-useful-links" className="menu">
                  <li id="menu-item-1304" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1304">
                    <Link to="/blogs">Blog</Link>
                  </li>
                  {/* <li id="menu-item-1303" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1303">
                    <Link to="https://www.ethicare.in/beauty-tips/">Beauty Tips</Link>
                  </li> */}

                  {/* <li id="menu-item-1306" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1306">

                    <Link to="https://www.ethicare.in/media-gallery/">Media Gallery</Link>

                  </li> */}

                  <li id="menu-item-1305" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1305">

                    <Link to="#">FAQ</Link></li>

                  {/* <li id="menu-item-1307" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1307">

                    <Link to="https://www.ethicare.in/testimonials/">Testimonials</Link>

                  </li> */}

                </ul>

              </div>

            </ul>

            </div>

            <div className="block col-sm-3">

              <h3>Contact Us</h3>

              <ul className="footer-links">

                <div className="menu-footer-contact-container"><ul id="menu-footer-contact" className="menu">

                  <li id="menu-item-707" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-707">

                    <a href="#">Contact Us</a>

                  </li>

                  {/* <li id="menu-item-706" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-706">
                    <a href="#">Trade Inquiry</a>
                  </li> */}
                </ul>
                </div>

              </ul>
            </div>
          </div>
        </div>
        <div id="copyright">

          <div className="container">
            <div className="row">

              <div className="col-sm-12" style={{fontSize:'12px'}}>

                <ul className="social-networks">

                  <li>

                    <a href="#" target="_blank"><i className="fa fa-facebook" />

                    </a>

                  </li>

                  <li><a href="#" target="_blank">

                    <i className="fa fa-twitter" />

                  </a>

                  </li>

                  <li>

                    <a href="#" target="_blank">

                      <i className="fa fa-google" />

                    </a>

                  </li>

                  <li>
                    <a href="#" target="_blank"><i className="fa fa-pinterest" /></a>

                  </li>

                  <li>
                    <a href="#" target="_blank"><i className="fa fa-linkedin" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <i className="fa fa-instagram" /></a></li>

                  <li>
                    <a href="#" target="_blank">
                      <i className="fa fa-youtube" />
                    </a>


                  </li>

                </ul>

                <center>
                  © {new Date().getFullYear()} Skin Science Laboratory Website Designed and Developed by
                  <a href="https://logicwebplus.com/ "> Logic Web Plus</a>

                </center>

              </div>
            </div>
          </div>
        </div>
        {/* <div className="floating-sidebar" style={{}}>

          <a className="floating-sidebar-button contact" href> <i className="fa fa-chevron-up" /> </a>

        </div> */}

      </footer>

    </>
  );
}

export default Footer;
