import React, { useEffect, useState, createContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../Component/Layout";
import Breadcrumbc from "../Component/Breadcrumbc";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import girlimage from "./girl-applying-serum.png";
import bottomBanner from "./bottom_banner.jpeg";

function ProductDetail() {
  const navigate = useNavigate();
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const [isChecked4, setIsChecked4] = useState(true);

  const slug = useParams();
  const [loading, setLoding] = useState(false);
  const [productinfo, setProductInfo] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState("");

  useEffect(() => {
    proDetail();
  }, [slug.slug]);

  const proDetail = () => {
    axios
      .get(`${BASE_URL}ProductView/${slug.slug}`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setProductInfo(response.data.data);
          console.log("productinfo ==>", productinfo);
          console.log("p ==>", response.data);
          setId(response.data.data._id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addToCart = (product, type) => {
    let tmpID = localStorage.getItem("tmpID");

    setLoding(true);
    let obj = {
      productId: product._id,
      quantity: 1,
      tmpUserID: tmpID,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}addToCart`, obj)
      .then(function (response) {
        // handle success
        if (response.data.success == true) {
          toast.success("Product added to cart successfully");
          setLoding(false);
          if (type == 2) {
            navigate("/addtocart");
          }
        } else {
          toast.error("You have already added this product in your cart");
          setLoding(false);
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const handleAccordionClick1 = () => {
    setIsChecked1(!isChecked1);
  };
  const handleAccordionClick2 = () => {
    setIsChecked2(!isChecked2);
  };
  const handleAccordionClick3 = () => {
    setIsChecked3(!isChecked3);
  };

  const handleAccordionClick4 = () => {
    setIsChecked4(!isChecked4);
  };

  return (
    <>
      <Layout>
        <div class="product_detail_content">
          <div class="product_detail_side">
            {" "}
            {productinfo.image ? (
              <div className="">
                <div className="fluid d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
                  <div className="fluid__image-container m-4">
                    <img
                      src={productinfo.image ?? ""}
                      alt="img"
                      className="img-fluid object-fit-cover shadow"
                      style={{ width: "auto", height: "auto" }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    src={productinfo.image ?? ""}
                    alt="img"
                    className="img-fluid d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none shadow"
                    style={{ width: "auto", height: "auto" }}
                  />
                </div>
              </div>
            ) : (
              " No image is available "
            )}
          </div>

          <div class="product_detail_main">
            <div className=" mt-5 mt-md-0 p-3 px-md-5">
              <strong
                className="fw-semibold text-capitalize"
                style={{ fontSize: "2em" }}
              >
                {productinfo.name}
              </strong>
              <div>
                <p style={{ fontWeight: "bolder", fontSize: "18px" }}>
                  Price: ₹{productinfo.price}
                </p>
                <p className="paraS">
                  {renderHTML(productinfo.short_decription)}
                </p>

                {/* <p>{renderHTML(productinfo.long_decription)}</p> */}

                <div className="d-flex m-2">
                  {productinfo.quantity > 0 ? (
                    loading ? (
                      <Spinner animation="border" />
                    ) : (
                      <button
                        type="button"
                        className="loginbtn m-2"
                        style={{ width: "180px", padding: "15px" }}
                        onClick={() => addToCart(productinfo, "1")}
                      >
                        Add to cart <i className="fa fa-shopping-cart m-2"></i>
                      </button>
                    )
                  ) : (
                    <button
                      type="button"
                      className="  loginbtn m-2"
                      disabled
                      style={{ width: "180px", padding: "15px" }}
                    >
                      Out of stock <i className="fa fa-shopping-cart"></i>
                    </button>
                  )}
                  {productinfo.quantity > 0 ? (
                    loading ? (
                      <Spinner animation="border" />
                    ) : (
                      <button
                        type="button"
                        className="loginbtn m-2"
                        style={{ width: "180px", padding: "15px" }}
                        onClick={() => addToCart(productinfo, "2")}
                      >
                        Buy now{" "}
                        <span
                          class="material-symbols-outlined "
                          style={{ fontSize: "larger" }}
                        >
                          shopping_bag
                        </span>
                      </button>
                    )
                  ) : (
                    <button
                      type="button"
                      className="loginbtn m-2"
                      style={{ width: "180px", padding: "15px" }}
                      onClick={() => addToCart(productinfo, "2")}
                      disabled
                    >
                      Buy now{" "}
                      <span class="material-symbols-outlined">
                        shopping_bag
                      </span>
                    </button>
                  )}
                </div>
                {/* accordian start  */}
                <div
                  className={`custom-accordion ${isChecked1 ? "checked" : ""}`}
                  onClick={handleAccordionClick1}
                >
                  <input type="checkbox" checked={isChecked1} readOnly />
                  <div className="custom-accordion-title">
                    Benefits
                    <span style={{ float: "right" }}>
                      <i className="fa fa-arrow-down accordian-down-arrow"></i>
                    </span>
                  </div>
                  <div
                    className={`custom-accordion-description ${
                      isChecked1 ? "show" : ""
                    }`}
                  >
                    <div className="py-3">
                      {renderHTML(productinfo.benefits)}
                    </div>
                  </div>
                </div>
                <div
                  className={`custom-accordion ${isChecked2 ? "checked" : ""}`}
                  onClick={handleAccordionClick2}
                >
                  <input type="checkbox" checked={isChecked2} readOnly />
                  <div className="custom-accordion-title">
                    Steps To Use
                    <span style={{ float: "right" }}>
                      <i className="fa fa-arrow-down accordian-down-arrow"></i>
                    </span>
                  </div>
                  <div
                    className={`custom-accordion-description ${
                      isChecked2 ? "show" : ""
                    }`}
                  >
                    <div className="py-3">
                      {" "}
                      {renderHTML(productinfo.howtouse)}
                    </div>
                  </div>
                </div>

                <div
                  className={`custom-accordion ${isChecked3 ? "checked" : ""}`}
                  onClick={handleAccordionClick3}
                >
                  <input type="checkbox" checked={isChecked3} readOnly />
                  <div className="custom-accordion-title">
                    Ingredients
                    <span style={{ float: "right" }}>
                      <i className="fa fa-arrow-down accordian-down-arrow"></i>
                    </span>
                  </div>
                  <div
                    className={`custom-accordion-description ${
                      isChecked3 ? "show" : ""
                    }`}
                  >
                    <div className="py-3">
                      {renderHTML(productinfo.ingredients)}
                    </div>
                  </div>
                </div>

                {/* accordian end  */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
          <div
            className={`custom-accordion ${isChecked4 ? "checked" : ""}`}
            onClick={handleAccordionClick4}
          >
            <input type="checkbox" checked={isChecked4} readOnly />
            <div
              className="custom-accordion-title"
              style={{ fontWeight: "bolder" }}
            >
              Description
              <span style={{ float: "right" }}>
                <i className="fa fa-arrow-down accordian-down-arrow"></i>
              </span>
            </div>
            <div
              className={`custom-accordion-description ${
                isChecked4 ? "show" : ""
              }`}
            >
              <div className="py-3">
                {renderHTML(productinfo.long_decription)}
              </div>
            </div>
          </div>
        </div>

        {productinfo && productinfo.banner && (
          <img src={productinfo.banner} width={"100%"} height={"auto"} alt="" />
        )}
      </Layout>
    </>
  );
}
export default ProductDetail;
