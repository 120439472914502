import React from 'react'
import "../App.css";
import { useNavigate } from 'react-router-dom';
import Changepassword from '../Pages/Changepassword';
export default function FilterNav() {
  const navigate= useNavigate();

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <>
<div className='filterNav' >
<nav style={{zIndex:"1",width:"100%!important",backgroundColor:"transparent"}}>
        <label htmlFor="toggleMainNav" className="hamburgermenu">

          Menu 
          <span className="material-symbols-outlined">
            expand_more
          </span>
        </label>
        <input type="checkbox" id="toggleMainNav" className="check-toggle" />
        {/* <label for="toggleMainNav" class="menu-overlay"></label> */}
        <div className="main-nav-holder">
          <ul className="main-nav">
            <li className="close-nav">
              <label htmlFor="toggleMainNav"></label>
            </li>
         
           
            <li className="has-submenu">
              <input type="checkbox" id="labelAntioxidants" className="check-toggle" />
              <label htmlFor="labelAntioxidants" onClick={()=>navigate('/change-password')}>Change password</label>
            </li>
        
         
            <li className="has-submenu">
              <input type="checkbox" id="labelMen" className="check-toggle" />
              <label htmlFor="labelMen" onClick={()=>navigate('/myprofile')}>My profile </label>
            </li>
            <li className="has-submenu">
              <input type="checkbox" id="labelWomen" className="check-toggle" />
              <label htmlFor="labelWomen" onClick={()=>navigate('/orders')}>Orders</label>
            </li>
            <li className="has-submenu">
              <input type="checkbox" id="labelbeautyproducts" className="check-toggle" />
              <label htmlFor="labelbeautyproducts" onClick={()=>logOut()}>Logout</label>
            </li>
          </ul>
        </div>


      </nav>
</div>
     
    </>
  )
}
