import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import Table from "react-bootstrap/Table";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./ordertable.css";

export default function Addtocart() {
  const navigate = useNavigate();

  const [cartItem, setCartItem] = useState([]);

  // const [cartItem, setCartItem] = useState([]);
  const [cartlen, setCartlen] = useState();
  const [quantity, setQuantity] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [user, setUser] = useState({});

  const loadCartList = () => {
    let tmpID = localStorage.getItem("tmpID");

    const obj = {
      tmpUserID: tmpID,
    };
    axios
      .post(`${BASE_URL}getAllCart`, obj, {
        headers: {
          version: "1.0.0",
          "Content-type": "Application/json",
        },
      })
      .then(function (response) {
        setCartItem(response.data.data);
        console.log("cart products:=================>", response.data.data);
        setCartlen(response.data.data.length);
        // console.log(response.data.data.length);
        // console.log("cart-cart-len",cartlen);
        // console.log("cart-length",response.data.data.length);
        // console.log("response image is",response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    loadCartList();
  }, []);

  const updateQnt = (type, cartID) => {
    if (type === "-") {
      setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
    let obj = {
      quantity: quantity,
    };
    axios
      .put(`${process.env.REACT_APP_BASE_URL}updateCart/${cartID}`, obj)
      .then(function (response) {
        console.log("cart len at add to cart", response);
        loadCartList();
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("cartID ==>", cartID);
    console.log("quantity ==>", quantity);
  };

  const deletCartItem = (cartID) => {
    if (window.confirm("Do you really want to delete this item?")) {
    } else {
      return;
    }
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}deleteCart/${cartID}`)
      .then(function (response) {
        console.log(response);
        toast.info(response.data.message);
        loadCartList();
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("cartID ==>", cartID);
    console.log("quantity ==>", quantity);
  };

  const [num, setNum] = useState(1);
  const increment = () => {
    setNum(num + 1);
  };
  const decrement = () => {
    if (num !== 1) {
      setNum(num - 1);
    }
  };
  return (
    <>
      <Layout>
        <div className="container pt-4">
          <table class="orderTable">
            <thead class="orderTablehead">
              <tr class="orderTr">
                <th class="orderTh">
                  <label>S no.</label>
                </th>
                <th class="orderTh">
                  <label>Image</label>
                </th>

                <th class="orderTh">
                  <label>Product name</label>
                </th>
                <th class="orderTh">
                  <label>Quantity</label>
                </th>
                <th class="orderTh">
                  <label>Price</label>
                </th>
                <th class="orderTh">
                  <label>Action</label>
                </th>
              </tr>
            </thead>

            <tbody class="">
              {cartItem.length > 0 &&
                cartItem.map((item, index) => (
                  <>
                    <tr class="orderTr">
                      <td class="ordertTd" data-label="S no.">
                        {index + 1}
                      </td>

                      <td class="ordertTd" data-label="Image">
                        <Link
                          to={`/product-details/${item.Product_details[0].slug}`}
                        >
                          <img
                            src={item.Product_details[0]?.image}
                            className="img-fluid"
                            alt={item.Product_details[0]?.name}
                            style={{ width: "100px" }}
                          />
                        </Link>
                      </td>
                      <td class="ordertTd" data-label="Product name">
                        {item.Product_details[0]?.name}
                      </td>
                      <td class="ordertTd" data-label="Qantity">
                        <h5 className="text-grey mt-1 mr-1 ml-1">
                          <i
                            className="fa fa-minus text-danger"
                            style={{ marginRight: 5 }}
                            onClick={() =>
                              item.quantity === 1
                                ? alert(
                                    "The quantity shall not be less than one."
                                  )
                                : updateQnt("-", item._id)
                            }
                          />
                          &nbsp;&nbsp;
                          {item.quantity}
                          &nbsp;&nbsp;
                          <i
                            className="fa fa-plus text-success"
                            style={{ marginLeft: 5 }}
                            onClick={() => updateQnt("+", item._id)}
                          />
                        </h5>
                      </td>
                      <td class="ordertTd" data-label="Amount">
                        {item.Product_details[0]?.price * item.quantity}
                        {/* {item.Product_details[0]?.price}  */}
                        <i className="fa fa-rupee"></i>
                      </td>
                      <td class="ordertTd" data-label="Action">
                        <i
                          className="fa fa-trash"
                          onClick={() => deletCartItem(item._id)}
                          style={{
                            width: "28px",
                            fontSize: " 25px",
                            color: "red",
                          }}
                        ></i>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>

          {/* <Table striped bordered hover style={{ overflow: "scrollX" }}>

            <tbody>
              {
                cartItem.length > 0 && cartItem.map((item, index) => (
                  <>
                    <tr className='text-center' >
                      <td ><img src={item.Product_details[0]?.image[0]} className='img-fluid' alt={item.Product_details[0]?.name} style={{ width: "100px" }} /></td>
                      <td className='pt-5'>SKIN ROLLER Seskavel 10ml</td>
                      <td className='pt-5'>{item.Product_details[0]?.name}</td>
                      <td className='pt-5'>

                       



                        <h5 className="text-grey mt-1 mr-1 ml-1">
                          <i
                            className="fa fa-minus text-danger"
                            style={{ marginRight: 5 }}
                            onClick={() =>
                              item.quantity === 1
                                ? alert("The quantity shall not be less than one.")
                                : updateQnt("-", item._id)
                            }
                          />
                          &nbsp;&nbsp;
                          {item.quantity}
                          &nbsp;&nbsp;
                          <i
                            className="fa fa-plus text-success"
                            style={{ marginLeft: 5 }}
                            onClick={() => updateQnt("+", item._id)}
                          />
                        </h5>


                      </td>

                      <td className='pt-5'>{item.Product_details[0]?.price}</td>
                      <td className='pt-5'><button style={{ border: "none", outline: "none" }}><i className='fa fa-trash' onClick={() => deletCartItem(item._id)} style={{ width: "28px", fontSize: " 25px", color: "red" }}></i></button></td>
                    </tr>
                  </>
                ))
              }


            </tbody>



          </Table> */}

          {cartItem.length > 0 ? (
            <div className="d-flex justify-content-center m-md-5 mb-5">
              <button
                type="button"
                className="loginbtn mt-3"
                style={{ width: "170px", color: "#fff" }}
                onClick={() => navigate("/")}
              >
                {" "}
                continue shipping
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="loginbtn mt-3"
                style={{ width: "190px", color: "#fff" }}
                onClick={() =>
                  navigate(
                    user
                      ? "/checkout"
                      : '<script>alert("please login first")</script>'
                  )
                }
              >
                {" "}
                proceed to checkout
              </button>
            </div>
          ) : (
            <>
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <h2>No record found</h2>
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
}
