import React, { useEffect, useState } from "react";
import ProductCard1 from "./ProductCard1";
import PerfectScrollbar from "react-perfect-scrollbar";
import productdet from "../data/newProductSlider.json";
import Carousel from "react-multi-carousel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { Link } from "react-router-dom";
const buttonStyle = {
  backgroundColor: "#007bff",
  border: "none",
  color: "white",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  cursor: "pointer",
  transition: "all 0.3s ease",
  position: "relative",
  boxShadow: "0 5px 0 #0056b3",
};
function TabsSlider() {
  const [newProducts, setNewProducts] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);

  console.log("product details", productdet);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  const getNewproducts = () => {
    axios
      .get(`${BASE_URL}newproducts`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
        } else {
          setNewProducts(response.data.data);
          console.log("new products", response.data.data);
        }
      });
  };

  const getBestSeller = () => {
    axios
      .get(`${BASE_URL}bestsellerproducts`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
        } else {
          setBestSeller(response.data.data);
          console.log("best seller", response.data.data);
        }
      });
  };

  useEffect(() => {
    getBestSeller();
    getNewproducts();
  }, []);

  const showOnly50Words = (text) => {
    const words = text.split(" ");
    if (words.length > 6) {
      return words.slice(0, 6).join(" ") + "...";
    }
    // console.log("50 word ==>", text);
    return text;
  };
  return (
    <div className="w-100 ">
      <Tabs className="w-80 mt-5">
        <TabList className="d-flex justify-content-center align-items-center">
          <Tab>
            <abbr
              title="New products"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontWeight: 600,
                textDecoration: "none",
              }}
            >
              New products
            </abbr>
          </Tab>
          <Tab
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <abbr
              title="BestSeller"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontWeight: 600,
                textDecoration: "none",
              }}
            >
              BestSeller
            </abbr>
          </Tab>
        </TabList>

        <TabPanel>
          <Carousel id="parentsdiv" responsive={responsive}>
            {newProducts.length > 0 &&
              newProducts.map((item, index) => (
                <div
                  key={index}
                  className="shadow-sm p-3 mb-5 bg-body-tertiary rounded"
                >
                  <Link to={`/product-details/${item.slug}`}>
                    <div
                      className=""
                      style={{ position: "relative", maxWidth: "100%" }}
                    >
                      <div>
                        <div>
                          <img
                            src={item.image ?? ""}
                            alt={item.name}
                            className="image"
                            style={{ width: "100%" }}
                          />
                          <h1 style={{ fontSize: "18px" }}>
                            {showOnly50Words(item.name)}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </Carousel>
        </TabPanel>
        <TabPanel>
          <Carousel id="parentsdiv" responsive={responsive}>
            {bestSeller.length > 0 &&
              bestSeller.map((item, index) => (
                <div
                  key={index}
                  className="shadow-sm p-3 mb-5 bg-body-tertiary rounded"
                >
                  <Link to={`/product-details/${item.slug}`}>
                    <div
                      className=""
                      style={{ position: "relative", maxWidth: "100%" }}
                    >
                      <div>
                        <div>
                          <img
                            src={item.image ?? ""}
                            alt="image"
                            className="image"
                            style={{ width: "100%" }}
                          />
                          <h1 style={{ fontSize: "18px" }}>
                            {showOnly50Words(item.name)}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </Carousel>
        </TabPanel>
      </Tabs>
    </div>
  );
}
export default TabsSlider;
