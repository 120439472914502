import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu';
import ProductCard1 from './ProductCard1';
import productdet from "../data/newProductSlider.json"
import axios from 'axios';
import { useParams } from 'react-router-dom';
console.log("product details in product card",productdet);

function Productcard() {
    const [products , setProducts]=useState([]);
    const slug=useParams();

    const BASE_URL=process.env.REACT_APP_BASE_URL;
    // const [slug , setSlug]=useState(props.slug);
    //  slug=props.slug;
    console.log("props in product",slug.slug);
    const relatedProducts=()=>{
        axios
        .get(`${BASE_URL}ProductsbyCategorySlug/${slug.slug}`,
        {
            headers:{
                "version":"1.0.0",
                "Content-Type":"Application/json"
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                console.log(response.data.message);
            }else{
                setProducts(response.data.data);
                console.log("related products are:",response.data.data);

            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        relatedProducts();
        setProducts([])
    },[slug.slug])
    return (
        <>
       
            <div className='container-fluid pt-5 pb-5' style={{ backgroundColor: "#fff", minHeight: "1000px" }}>


                <div className=' '>
                    <div className='row'>
                     


                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                            <div className='row'>

                            {
                            products.length>0&& products.map((item,index)=>(
                                

                                <div key={index} className='col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center' style={{marginTop:"15px"}}>


                                <ProductCard1  product={item}/>


                            </div>
                                
                            ))
                        }

                              

                                {/* 
                                
                                <div className='col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center'>


                                    <ProductCard1 product={productdet}/>


                                </div>

                                <div className='col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center'>


                                    <ProductCard1 product={productdet} />


                                </div>

                                <div className='col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center'>
                                    <ProductCard1 product={productdet}/>
                                   


                                </div>

                                <div className='col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center'>
                                  

                                    <ProductCard1 product={productdet} />



                                </div>

                                <div className='col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center'>
                                   

                                    <ProductCard1 product={productdet} />



                                </div> 
                                
                                
                                */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Productcard;
