import React, { createContext, memo, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
// import RecordNotFound from "../Components/RecordNotFound";
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// import ProductBreadcrumb from "../Components/productBreadcrumb";
import Home from "./Home";
import {CartContext} from "../App"
import '../App.css'
// import { CartContext } from '../App';

import Layout from "../Component/Layout";
import Footer from "../Component/Footer";
import NavMenu from "../Component/NavMenu";


// const Layout = React.lazy(() => import('../Components/Layout'));
// const PageBreadcrumb = React.lazy(() => import('../Components/Breadcrumb'));
// import {cartItm} from "../App"

// const cartL = createContext();


const ProductsInfo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // const cartitem=useContext(cartItm);
  const [Item, setItem] = useState(state.product_details);
  // const [totalAmount, setTotalAmount] = useState(0);
  const [modalShow, setModalShow] =useState(false);
  // const [item , setItem]=useState([]);
  const [image , setImage]=useState("");
  const [file, setFile] = useState('');
  const [name , setName]=useState("");
  const [review , setReview]=useState("");
  const [productId , setProductId]=useState("");
  const [id, setId]=useState();
  const [productInfo , setProductInfo]=useState([]);
  // const state = useContext(CartContext);
  console.log(state,"state==============================>")
  // const [cartItem, setCartItem] = useState([]);
  const [cartlen, setCartlen] = useState();
  const [quantity, setQuantity] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [user, setUser] = useState({});

  // const loadCartList = () => {
  //   let tmpID = localStorage.getItem("tmpID");

  //   const obj = {
  //     tmpUserID: tmpID,
  //   };
  //   axios
  //     .post(`${BASE_URL}getAllCart`, obj,
  //       {
  //         headers: {
  //           "version": "1.0.0",
  //           "Content-type": "Application/json"
  //         }
  //       })
  //     .then(function (response) {
  //       setCartItem(response.data.data);
  //       console.log(response.data.data);

  //       setCartlen(response.data.data.length);

  //       let valueAdded = 0;

  //       for (let i = 0; i < response.data.data.length; i++) {
  //         if (response.data.data[i].price !== undefined) {
  //           valueAdded +=
  //             response.data.data[i].price * response.data.data[i].quantity;
  //         } else if (
  //           response.data.data[i].Product_details &&
  //           response.data.data[i].Product_details[0].price !== undefined
  //         ) {
  //           valueAdded +=
  //             response.data.data[i].Product_details[0].price *
  //             response.data.data[i].quantity;
  //         }
  //       }
  //       if (valueAdded != 0) {
  //         setTotalAmount(valueAdded);
  //         console.log('valueAdded', valueAdded);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const updateQnt = (type, cartID) => {
  //   if (type === "-") {
  //     setQuantity(quantity - 1);
  //   } else {
  //     setQuantity(quantity + 1);
  //   }
  //   let obj = {
  //     quantity: quantity,
  //   };
  //   axios
  //     .put(`${process.env.REACT_APP_BASE_URL}updateCart/${cartID}`, obj)
  //     .then(function (response) {
  //       console.log(response);
  //       loadCartList();
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   console.log("cartID ==>", cartID);
  //   console.log("quantity ==>", quantity);
  // };

  // const deletCartItem = (cartID) => {
  //   axios
  //     .delete(`${process.env.REACT_APP_BASE_URL}deleteCart/${cartID}`)
  //     .then(function (response) {
  //       console.log(response);
  //       toast.info(response.data.message);
  //       loadCartList();
  //       // state?.loadCartList();
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   console.log("cartID ==>", cartID);
  //   console.log("quantity ==>", quantity);
  // };
  // useEffect(() => {
  //   let user = localStorage.getItem("user");
  //   if (user != null) {
  //     setUser(user);
  //   }
  //   loadCartList();
  // }, []);

  const addImage = (e) => {
    setFile(e.target.files);
    // Check if files are selected
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setImage((e) => [newImageSrc]);

    }
  }


  const addReview=()=>{
    const fd=new FormData();
console.log("id",id);
    let api="addTestimonial";
  
    const token = localStorage.getItem("token");

    if(id){
      api="updateTestimonial";
      fd.append("id",id)
    }
    fd.append("productId",productId);
    fd.append("image",file[0]);
    fd.append("description",review);
    fd.append("rate",rating);
    fd.append("name",name);
  

    axios
    .post(`${BASE_URL}${api}`,fd,{
        headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            "version":"1.0.0"
          }
    }).then((response)=>{
        if(response.data.success){
          setId("");
          setModalShow(false)
            toast.success(response.data.message);
        }
    }).catch((error)=>{
        console.log(error.message);
    })
  }



  const getReview=(id)=>{
    let token= localStorage.getItem("token");
    axios
    .get(`${BASE_URL}getTestimonial/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        "version":"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
       setImage(response.data.data[0].image);
       console.log("review",response.data.data[0].description);
       setReview(response.data.data[0].description);

       setRating(response.data.data[0].rate);
       console.log("rate",response.data.data[0].rate);
       setId(response.data.data[0]._id)
      //  setProductId(response.data.data.productId);
       
      }
    })
  }


  const [rating, setRating] = useState(0);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
    // console.log(selectedRating);
  };
  const openModel=async(item)=>{
   setProductInfo(item);

    console.log("itemmmmm",item);
    setName(state.customer_name);
    setProductId(item._id);
    // console.log("image",item.cart[0].Product_details[0].image[0]);
    setModalShow(true)
    getReview(item._id);
  }
  return (
    <>
      {/* <cartL.Provider value={cartlen}> */}

      {/* <Layout> */}
      <header class="header">
        {/* <TopBar /> */}
        <NavMenu /> 
      </header>
      {/* <ProductBreadcrumb
        home={
          <i className="fa fa-home" style={{ fontSize: '25px' }}>
            {' '}
            &nbsp;
            <span
              className="	fa fa-angle-right"
              style={{ fontSize: '25px' }}
            ></span>
            &nbsp;
          </i>
        }
        slugObj1="Cart"
      /> */}

      <div className="container">
      {Item && Item.length > 0 && (
        <>
          <div class="product_detail_content">
            <div class="product_detail_main">
              <div className="basket">
                {/* <label htmlFor="promo-code">Enter a promotional code</label> */}
                {/* <div className="basket-module">
              <input
                id="promo-code"
                type="text"
                name="promo-code"
                maxLength={5}
                className="promo-code-field"
              />
              <button className="promo-code-cta">Apply</button>
            </div> */}
                <div className="basket-labels">
                  <ul>
                    <li className="cart-Listt-heading item item-heading fs-4">
                      Item
                    </li>
                    {/* <li className=" cart-Listt-heading quantity fs-4">
                      Quantity
                    </li> */}
                    <li className="cart-Listt-heading price fs-4">Price</li>
                    {/* <li className="cart-Listt-heading price fs-4">Price</li> */}


                    {/* <li className="cart-Listt-heading subtotal fs-4">Action</li> */}
                  </ul>
                </div>

                {Item.length > 0 &&
                  Item.map((item, index) => (
                    <>
                      <div className="basket-product" index={index}>
                        <div className="item">
                          <div className="product-image">
                            <Link to={`/product-details/${item.slug}`}>
                          
                            <img
                              src={item.image[0]}
                              alt={item.name}
                              className="product-frame img-fluid"
                            />
                              </Link>
                          </div>
                          <div className="product-details">
                            <h2>
                              <strong>{item.name}</strong>{' '}
                            </h2>
                            {/* <p className="fs-4">
                              <strong>Size:{item.size}</strong>
                            </p> */}
                            <p className="fs-4">
                              Product Code - {item.productId}
                            </p>
                          </div>
                        </div>
                        <div className="quantity">
                          {/* <input
                        type="number"
                        defaultValue={4}
                        min={1}
                        className="quantity-field"
                      /> */}

                          <h5 className="text-grey mt-1 mr-1 ml-1fs-4 price w-100" >
                            {/* <i
                              className="fa fa-minus text-danger fs-4"
                              style={{ marginRight: 5 }}
                              // onClick={() =>
                              //   item.quantity === 1
                              //     ? alert(
                              //         'The quantity shall not be less than one.'
                              //       )
                              //     : updateQnt('-', item._id, item)
                              // }
                            /> */}
                         {item.price
                            }
                            {/* <i
                              className="fa fa-plus text-success fs-4"
                              style={{ marginLeft: 5 }}
                              // onClick={() => updateQnt('+', item._id, item)}
                            /> */}
                          </h5>
                        </div>
                        <div className=" fs-4">
                          {' '}
                         
                        </div>

                        <div className="subtotal fs-4 d-flex justify-content-end">

                        {/* <button
                                className="btn btn-primary btn-sm m-1 float-right"
                                style={{ fontSize: '15px' }}
                               
                              >
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </button> */}
                          <button
                            className="btn loginbtn "
                            onClick={() => openModel(item)}
                          >
                           Rate Product                         
                            </button>
                        </div>
                        {/* <div className="remove">
                          <button
                           
                            className="fs-4"
                          >
                            Remove
                          </button>
                        </div> */}
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div class="product_detail_side">
              {' '}
              {/* <aside>
                <div className="summary">
                  <div className="summary-total-items">
                    <span className="total-items fs-4"> Items in your Bag</span>
                  </div>

                  {Item.length > 0 &&
                    cartItem.map((item, index) => (
                      <>
                        <div className="row p-3">
                          <div className="col fs-4 text-capitalize">
                            {item.Product_details[0].name}
                          </div>
                          <div className="col text-end fs-4">
                            {item.price ? (
                              <>
                                {item.quantity} x {item.price} =
                                {item.quantity * item.price}
                              </>
                            ) : (
                              <>
                                {item.quantity} X{' '}
                                {item.Product_details[0]?.price} ={' '}
                                {item.quantity * item.Product_details[0]?.price}
                              </>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                 
                  <div className="summary-total">
                    <div className="total-title fs-4">Total</div>
                    <div className="total-value final-value fs-4" id="basket-total">
                      {totalAmount}
                    </div>
                  </div>
                  <div className="summary-checkout">
                    <button
                      className="checkout-cta fs-4"
                      onClick={() =>
                        navigate(
                          user
                            ? '/checkout'
                            : '<script>alert("please login first")</script>'
                        )
                      }
                    >
                      Go to Secure Checkout
                    </button>
                  </div>
                </div>
              </aside> */}
            </div>
          </div>
        </>
      ) 
      // : (
      //   <>
      //     <div className="h-100 d-flex justify-content-center align-items-center">
      //       <div>
      //         <img
      //           src="https://img.freepik.com/free-photo/3d-man-with-shopping-cart-blank-screen-tablet_1401-15.jpg?size=626&ext=jpg&ga=GA1.1.1715450231.1708072499&semt=ais"
      //           style={{ width: '300px' }}
      //         />
      //         <h3 className="text-center">Cart Is Empty</h3>
      //       </div>
      //     </div>
      //   </>
      // )
      }
      </div>

    <div className="d-block">
    <Footer/>

    </div>




    <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Review Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row border-bottom align-items-center pb-2">
              {/* <div className="col-3 col-lg-2">
                {productInfo.length>0 &&
                  productInfo.image[0]&&
                  (
                    <img
                      src={productInfo.image[0]}
                      className="w-100 rounded-1"
                      style={{
                        aspectRatio: 4 / 4,
                        objectFit: 'cover',
                        objectPosition: 'top',
                      }}
                    />
                  )}
              </div> */}
              <div className="col-12 col-lg-10 lh-sm paraS">
                {productInfo&&
                  productInfo.name &&
                  (
                    // <img src={productInfo.cart[0].Product_details[0].image[0]} className='w-100 rounded-1' style={{ aspectRatio: 4 / 4, objectFit: "cover", objectPosition: "top" }} />
                    <p className="small fw-semibold paraS">
                      { productInfo.name}
                    </p>
                  )}
                {/* <p className='small fw-semibold'></p> */}
                {/* <p>STARs</p> */}
                <div
                  className="d-flex gap-1 fs-5"
                  style={{ color: 'rgb(235, 189, 23)' }}
                >
                  {[1, 2, 3, 4, 5].map((index) => (
                    <span
                      key={index}
                      value={rating}
                      onClick={() => handleStarClick(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      {index <= rating ? (
                        <i className="fa fa-star" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className="mt-3">
                <p className="paraS">Write a Review</p>
              </div>
              <div>
                <textarea
                  name="textarea"
                  style={{ width: '100%' }}
                  placeholder="Write a review"
                  className="p-2 rounded-1"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                >
                  {/* {review} */}
                </textarea>
              </div>
            </div>
            {/* <button onClick={()=>console.log(state)}>click</button> */}

            <div className="my-3 border-bottom pb-3 ">
              {/* <div>
              <p className='text-center '>Add Photo</p>
            </div> */}
              <div>
                {/* <p>product id is {productId}</p> */}

                <div className="d-flex gap-5 align-items-center justify-content-lg-start justify-content-center">
                  <label for="Add" className="btn btn-outline-dark">
                    <i className="fa fa-camera me-2" aria-hidden="true"></i>Add
                    Image
                  </label>
                  <input
                    type="file"
                    className="form-control border-0 border-bottom border-1 border-secondary d-none"
                    id="Add"
                    onChange={addImage}
                  />
                  <img
                    src={image}
                    className="rounded-1 col-lg-2 col-3 img-fluid "
                    style={{ aspectRatio: 4 / 4, objectFit: 'cover' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center m-auto">
          <Button
            // onClick={props.onHide}
            className="btn border-0 bg-dark text-white paraS"
            onClick={() => addReview()}
          >
            Sumbit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Footer /> */}
      {/* </Layout> */}
    </>
  );
}
export default ProductsInfo;