import React, { useEffect, useState } from 'react'
import Layout from '../Component/Layout'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

function Childcategories() {
    const [childCategories, setChildCategories] = useState([]);
    const slug = useParams();
    console.log(slug);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const childcategories = () => {
        axios
            .get(`${BASE_URL}childcategorybysubcategory/${slug.slug}`, {
                headers: {
                    "Content-Type": "Application/json",
                    "version": "1.0.0"
                }
            }).then((response) => {
                if (response.data.success) {
                    setChildCategories(response.data.data);
                } else {
                    setChildCategories([])
                }
            })
    }
    useEffect(() => {
        childcategories();
    }, [slug.slug])
    return (
        <>
            <Layout>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }} className='cateview'>
                    <h3 style={{ fontSize: "40px", color: "#fff" }}>{slug.slug}</h3>
                </div>
                <div className='container-fluid my-5'>
                    <div className='row'>
                        {
                            childCategories && childCategories.map((item, index) => (
                                <>
                                    <div className='col-xl-3 col-md-3 col-6 cardd' key={index}>
                                        <Link to={`/childcategoryview/${item.slug}`}>

                                            <div className='shadow'>
                                                <div className='position-relative overflow-hidden'>
                                                    <img src={`${item.image}`} className='img-fluid object-fit-cover img-hover' style={{ aspectRatio: "4/4", objectPosition: "top" }} alt='img' />

                                                    <div className='text-center bg-body-secondary rounded-circle position-absolute favrate px-2 py-1'><span className='fs-6 fw-semibold'
                                                    > <i class="fa fa-heart" aria-hidden="true" style={{ color: "gray" }}></i></span></div>
                                                </div>
                                                <div className=' d-flex flex-column text-center align-items-center justify-content-center p-3 '>
                                                    <div className='mb-2 text-secondary fw-semibold small text-uppercase'>{item.name}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            ))
                        }


                    </div>

                    {
                        childCategories.length > 0 ? "" :
                            <div className='d-flex justify-content-center align-items-center ' style={{ height: '300px' }}>
                                <div>
                                    No Child  Category Found!
                                </div>
                            </div>
                    }

                </div>
            </Layout>

        </>
    )
}

export default Childcategories