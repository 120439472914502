import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Layout from '../../Component/Layout';
import {ValidMobileNumber} from "../../Functions/Validation"
// import { Form } from 'react-router-dom'

function Register() {
    const [fullname , setFullname]=useState("");
    const [password , setPassword]=useState();
    const [confirmpss, setConfirmpss]=useState();
    const [mobile_no, setMobile_no]=useState("");

    const [email , setEmail]=useState("");
    const navigate=useNavigate();
    const BASE_URL=process.env.REACT_APP_BASE_URL;

    const Submitinfo = () => {
        console.log("hiii it has click");
        if(password!=confirmpss)
        {
            toast.error("Confirm  password is not matching to the password ");
            return;
        }

        const obj = {
            name: fullname,
            email: email,
            mobile_no:mobile_no,
            // role:"3",
            password: password,

        };

        


        
        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        }
        if (email.length > 50) {
            toast.error("please enter valid email address length ");
        }
        else {
            if (isValidEmail(email)) {
                setEmail(email);
            }
            else {
                toast.error("please enter valid email address");
            }
        }

        if (!ValidMobileNumber(mobile_no)) {
            toast.error("Please enter valid mobile number")
            return;
        }
        if(password.length>6)
        {
            toast.error("password must be upto 6 digit ");
        }
        else if(password.length<=3){
      toast.error("password must be of minimum 4 digit");
        }



        axios
        .post(`${BASE_URL}auth/register`,obj,
        {
            headers:{
                "version":"1.0.0",
                "Content-type":"Application/json",
            }
        }).then(function(response){
            if(response.data.success==false){
                // toast.error(response.data.message);
                // console.lof(REACT_APP_BASE_URL)
            }
            else{

                localStorage.setItem("User", response.data.data)
                // console.log( "login response",response.data.data);
                toast.success(response.data.message);
                localStorage.setItem("token",response.data.data.token)
                // console.log(REACT_APP_BASE_URL)

                navigate("/");

            }
          
        }
        )
        .catch(function(error){
               console.log(error); 
        })
       
    }
  

    return (
        <>
        <Layout>
        <div>
         <div className=''
                    style={{ backgroundColor: "#c4c5ca" }}
                >


<div className='container  p-5 ' >

<div className='row  d-flex justify-content-center align-items-center'>

    <div className='col-md-1'>
    </div>

    <div className='col-md-7 p-4'>
        <div className='p-5' style={{ backgroundColor: "#f2f2f2" }}>
            {/* <Form> */}
                <div><h1 className='p-3'>Register </h1></div>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className='pt-3'>Fullname</Form.Label>
                    <input type="text" placeholder="Enter Full Name"
                        name='fullname'
                        value={fullname}
                        onChange={(e)=>setFullname(e.target.value)}
                    className='checkoInput' />

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className='pt-3'>Email address</Form.Label>
                    <input type="email" placeholder="Enter email"
                       name='email'
                       value={email}
                       onChange={(e)=>setEmail(e.target.value)}
                    className='checkoInput' />

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className='pt-3'>Mobile Number </Form.Label>
                    <input type="text" placeholder="Enter Mobile Number"
                       name='mobile_no'
                       value={mobile_no}
                       onChange={(e)=>setMobile_no(e.target.value)}
                    className='checkoInput' />

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className='pt-3'>Password</Form.Label>
                    <input type="password" placeholder="Enter password"
                     name='password'
                     value={password}
                     onChange={(e)=>setPassword(e.target.value)}
                    className='checkoInput' />

                </Form.Group>
                
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className='pt-3'>Confirm Password</Form.Label>
                    <input type="password" placeholder="Enter Confirm Password"
                       name='confirmpss'
                       value={confirmpss}
                       onChange={(e)=>setConfirmpss(e.target.value)}
                    className='checkoInput' />

                </Form.Group>





             

                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group> */}
                <br/>
                <br/>
                <Button variant="success" type="submit" style={{ width: "100%", padding: "10px", backgroundColor:"#364255" }}
                onClick={()=>Submitinfo()}
                >
                  <h3>Register</h3>    
                </Button>
                <div className='pt-3'>
                    <br/>
                    <br/>
      <p>Don't have an account <u onClick={()=>navigate("/login")} style={{cursor:"pointer"}}>Login here</u></p>
    </div>


            {/* </Form> */}


            {/* <Form>
<div><h1 className='p-3'>Billing details</h1></div>

<Form.Group className="mb-3" controlId="formBasicEmail">
<Form.Label>Email address</Form.Label>
<input type="email" placeholder="Enter email"
// onChange={handleChange}
// name="customer_email_id"
// value={values.customer_email_id}
className='checkoInput' />

</Form.Group>




<Form.Group className="mb-3" controlId="formBasicCheckbox">
<Form.Check type="checkbox" label="Check me out" />
</Form.Group>
<Button variant="success" type="submit" style={{ width: "100%", padding: "10px" }} 
// onClick={()=>createOrder()}
>
Place order
</Button>



</Form> */}
        </div>

    </div>
    <div className='col-md-1'>
    </div>

</div>


</div>
                </div>
          
        </div>
        </Layout>
       
            

        </>
    )
}
export default Register;
