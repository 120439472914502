import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import IndianStates from '../data/IndianStates.json'

// import './myprofile.css'
import Layout from '../Component/Layout';
import FilterNav from '../Component/FilterNav';
import { Table } from 'react-bootstrap';
import { Form, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


const initialState = {
  name: "",
  // lastname: "",
  // email: "",
  mobile_no: "",
  address: "",
  address2: "",
  state: "",
  city: "",
  zip_code: "",
};
export default function Myprofile() {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  const [states, setStates] = useState([]);

  // setUserStates

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    setStates(IndianStates);
    loadProfile();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

  };

  const loadProfile = () => {
    let token = localStorage.getItem("token");

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}auth/view-profile`,

        {
          headers: {
            "x-access-token": ` ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.success === false) {
          // toast.error(response.data.message);
          if(response.data.error_code==461){
            navigate("/login");
          }
        } else {
          setValues(response.data.data);
          console.log("values", response.data.data);

          // toast.success(response.data.message);
          // localStorage.setItem("user", response.data.data);
          // navigate("/");
        }
        // console.log("response =>", response.data.success);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };


  const updateProfile = () => {
    if(!values.mobile_no){
      toast.error("Mobile number is mandatory");
      return;
    }
    if(!values.mobile_no){
      toast.error("Mobile number is mandatory");
      return;
    }
    if(!values.address){
      toast.error("Address is mandatory");
      return;
    }
    if(!values.state){
      toast.error("State is mandatory");
      return;
    }
    if(!values.city){
      toast.error("city is mandatory");
      return;
    }
    if(!values.zip_code){
      toast.error("Pincode is mandatory");
      return;
    }
    let token = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/update-profile`, values, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  return (
    <>

      <Layout>





        <div className=''>


          <FilterNav />
          <div className='container mt-5 mb-5'>
            <div className=''  >

              <div className='row  d-flex justify-content-center' >
                <div className='col-md-8 p-4 pb-5' style={{ backgroundColor: "#f2f2f2", boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>

                  <div className='px-5' >
                    <h1 className='text-center p-3'><u>My profile</u></h1>
                    <div className='row'>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='p-3'>Fullname</label>
                          <input type="text" placeholder="Enter name"
                            onChange={
                              handleChange
                            }
                            name="name"
                            value={values.name}
                            className='checkoInput' />

                        </div>
                      </div>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='p-3'>Mobile number</label>
                          <input type="number" placeholder="Enter mobile number"
                            onChange={handleChange}
                            name="mobile_no"
                            value={values.mobile_no}
                            className='checkoInput' />

                        </div>
                      </div>
                    </div>
                    <div className="mb-3" controlId="formBasicEmail">
                      <label className='py-3'>Email Id</label>
                      <input type="email" placeholder="Enter email"
                        onChange={handleChange}
                        name="email"
                        disabled
                        value={values.email}
                        className='checkoInput bg-white' />

                    </div>
                    <div className="mb-3" controlId="formBasicEmail">
                      <label className='py-3'>Address 1</label>
                      <input type="text" placeholder="Enter addess"
                        onChange={handleChange}
                        name="address"
                        value={values.address}
                        className='checkoInput' />

                    </div>
                    <div className="mb-3" controlId="formBasicEmail">
                      <label className='py-3'>Address 2</label>
                      <input type="text" placeholder="Enter address2"
                        onChange={handleChange}
                        name="address2"
                        value={values.address2}
                        className='checkoInput' />

                    </div>


                    <div className='row'>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='py-3'>state</label>

                          <select className="checkoInput"

                            name="state"
                            value={values.state}
                            onChange={handleChange}
                          >
                            <option value="">--select--</option>
                            {
                              states.length > 0 && states.map((item, index) => (
                                <option value={item.name} key={index}


                                >{item.name}</option>
                              ))
                            }

                          </select>











                          {/* <select className="checkoInput"  
        onChange={handleChange}
>
                      <option>--State--</option>
                      {states.length > 0 &&
                        states.map((item) => (
                          <option
                            key={item.key}
                            value={item.name}
                            selected={values.state == item.name ? true : false}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select> */}


                          {/* <input type="text" placeholder="Enter email" className='checkoInput' /> */}

                        </div>
                      </div>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='py-3'>City</label>
                          <input type="text" placeholder="Enter city"
                            onChange={handleChange}
                            name="city"
                            value={values.city}
                            className='checkoInput' />

                        </div>
                      </div>
                      <div className='col'>
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className='py-3'>Pincode</label>
                          <input type="number" placeholder="Enter Pincode"
                            onChange={handleChange}
                            name="zip_code"
                            value={values.zip_code}
                            className='checkoInput' />

                        </div>
                      </div>
                    </div>

                    <div className="mb-3 mt-3" controlId="formBasicEmail">
                      <button className='btn' style={{ width: "100%", padding: "10px", backgroundColor: "#364255", color: "#fff" }} onClick={() => updateProfile()}><h3>Update profile</h3></button>

                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>





      </Layout>





    </>
  )
}
