import React, { useEffect, useState } from 'react'
import Layout from '../Component/Layout'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


function Blog() {
  const [blogs, setBlogs] = useState([]);
  const BASE_URL=process.env.REACT_APP_BASE_URL;
  const navigate=useNavigate();
    const getBlogs = () => {
        axios
          .get(`${BASE_URL}getblogs`,
            {
              headers: {
                "Content-Type": "Application/json",
                "version": "1.0.0",
              }
            }).then((response) => {
              if (response.data.success == false) { console.log(response.data.message) }
              else {
                setBlogs(response.data.data);
                console.log(response.data.data);
    
              }
    
            }).catch((error) => {
              console.log(error);
            })
      }
      useEffect(()=>{
        getBlogs()
      },[])


      const renderHTML = (rawHTML) =>
      React.createElement("div", {
         dangerouslySetInnerHTML: { __html: rawHTML },
      });
      
    return (
        <>
<Layout>

            <div className="columns posts">
            <div  style={{ display: "flex",
    justifyContent: "center",
    alignItems: "center",}} className='cateview'>
<h3 style={{fontSize:"40px",color:"#fff"}}>Blogs</h3>
        </div>

                <div className='container mt-5'>
                    {
                        blogs.length>0&&blogs.map((item , index)=>(
                            <>
                               <div className='row mb-3' onClick={()=>navigate(`/blog-detail/${item.slug}`)} key={index} style={{cursor:"pointer",border:"1px solid #efefef"}}>
                        <div className=' col-4 col-xs-6 col-sm-6 col-md-3 p-0'><div ><img src={item.image} alt loading="lazy"  width={100} style={{width:"100%",padding:"10px"}}/> </div></div>
                        <div className='col'>
                            <p className=' qouteheading' >{item.title}</p>
                           
                            <p className='quote'>{renderHTML(item.description)}&nbsp;View more </p>
                            <span className='ms-md-4 ps-md-4 pt-md-4' style={{fontWeight:"600"}}>...View more</span>
                            <span style={{paddingLeft:"20px",marginLeft:"20px",fontWeight:"600",float:"right"}}>{moment(item.createdAt).format('LL')}</span>
                            </div>

                    </div>
                            </>
                        ))

                    }
                 
                </div>
            </div>
            </Layout>

        </>
    )
}

export default Blog