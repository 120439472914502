import React, { useEffect, useState } from 'react'
import Layout from '../Component/Layout'
import { useParams } from 'react-router-dom'
import NavMenu from '../Component/NavMenu';
import Footer from '../Component/Footer';
import axios from 'axios';
import Productcard from '../Component/Productcard';
import FilterNav from '../Component/FilterNav';
import ProductCard1 from '../Component/ProductCard1';

export default function Childcategoryview() {
  const slug=useParams();
  console.log(slug);

  const [categorySlug , setCategorySlug]=useState("");
  const [childCategories , setChildCategories]=useState([])
  const [products , setProducts]=useState([]);
  const BASE_URL=process.env.REACT_APP_BASE_URL;

const childCategoryProducts=()=>{
  axios
  .get(`${BASE_URL}productsbychildcategory/${slug.slug}`,
  {
    headers:{
      "version":"1.0.0",
      "Content-Type":"Application/json"
    }
  }).then((response)=>{
    if(response.data.success==false)
    {
      console.log(response.data.message);
      setProducts([])

    }
    else{
      setProducts(response.data.data);
      // console.log(" category view slug",response.data.data[0].slug);
    }
  })
}

const childCategory=()=>{
  axios
  .get(`${BASE_URL}childcategorybyslug/${slug.slug}`,{
    headers:{
      'Content-Type':"Application/json",
      "version":"1.0.0"
    }
  }).then((response)=>{
    if(response.data.success){
      setChildCategories(response.data.data);
      console.log("response.data.data",response.data.data);

    }
  })
}
useEffect(()=>{
  // AllCategory();
  childCategory();
  childCategoryProducts();
  // console.log("slug",slug);
},[slug])

// useEffect(() => {
//   if (slug != undefined && slug != null) {
//     setCategorySlug(slug.slug);
//   }
// }, [slug]);

// if(slug!=undefined&&slug!=null)
// {
//   setCategorySlug(slug.slug);
// }



  return (
 <>
 {/* <Layout> */}
 <NavMenu/>
 <div  style={{ display: "flex",
    justifyContent: "center",
    alignItems: "center",}} className='cateview'>
<h3 style={{fontSize:"40px",color:"#fff"}}>{slug.slug}</h3>
        </div>
        {/* <FilterNav/> */}
{/* product card start  */}


<div className='container-fluid pt-5 pb-5' style={{ backgroundColor: "#fff", minHeight: "1000px" }}>


<div className=' '>
    <div className='row'>
     


        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12' style={{marginTop:"15px"}}>
            <div className='row'>
            {
            products&& products.map((item,index)=>(
                <div className='col-6 col-xs-6 col-sm-6  col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center d-flex justify-content-center align-items-center' key={index}>
                <ProductCard1 product={item}/>
            </div>
            ))
        }
         </div>
        </div>
    </div>
</div>
</div>



{/* product card end  */}
<Footer/>

 {/* </Layout> */}
 </>
  )
}
