import React, { useContext, useEffect } from "react";
import { useState } from "react";
// import "./mobilemenu.css";
import "./mobilemenu.css";
import { webinfo } from "../App";

import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
export default function Mobilemenu(props) {
  const navigate = useNavigate();
  const websiteInfo = useContext(webinfo);
  //   const [showmenu, setShowmenu] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getAllcategories = () => {
    axios
      .get(`${BASE_URL}getAllCategory`, {
        headers: {
          "Content-Type": "application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          // console.log()
        } else {
          setAllCategories(response.data.data);
          console.log("categories", response.data.data);
        }
      });
  };

  useEffect(() => {
    getAllcategories();
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      <Offcanvas show={props.show} onHide={props.onHide}>
        <Offcanvas.Header
          closeButton
          //  style={{ backgroundColor: "#b7becbda" }}
        >
          <Offcanvas.Title>
            <img src={websiteInfo.logo} alt="" width={300} height={80} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
        // style={{ backgroundColor: "#b7becbda" }}
        >
          <ul className="p-0 ">
            <li className="p-4 mb-3 pt-0">
              <Link to={`/`}>Home</Link>
            </li>
            <li className="p-4 pt-0">
              <Link to={`/About-Us`}>About Us</Link>
            </li>

            <li className="p-4 mb-3 pt-0">
              <Link to={`/blogs`}>Blogs</Link>
            </li>
            <li className="p-4 pt-0">
              <Link to={`/contact-us`}>Contact Us</Link>
            </li>

            {allCategories.length > 0 &&
              allCategories.map((item, index) => (
                <>
                  <li
                    className="p-4"
                    key={index}
                    //  style={{ backgroundColor: "#b7becbda"}}
                  >
                    <p id="mobileNavLinks">
                      {" "}
                      <Link to={`/#`} className="desktop-item dottedText">
                        {item?.name}
                      </Link>
                      {item.subCategory.length > 0 ? (
                        <span
                          class="material-symbols-outlined text-end"
                          style={{ float: "right" }}
                        >
                          expand_more
                        </span>
                      ) : (
                        ""
                      )}
                    </p>

                    <ul className="submobilemenu">
                      <br />

                      {item.subCategory.length > 0 &&
                        item.subCategory.map((item1, index1) => (
                          <>
                            <li className="p-3" key={index1}>
                              <Link
                                to={`/child-categories/${item1.slug}`}
                                id="subCategoryLink"
                              >
                                {item1.name}
                              </Link>
                              {/* <a id='submenuLinks' href="" >{item1.name}</a> */}
                            </li>
                            {/* <br/> */}
                          </>
                        ))}
                    </ul>
                  </li>
                </>
              ))}

            <br />
            {localStorage.getItem("token") && (
              <>
                <li className="p-4  pt-0">
                  <Link to={`/orders`}>Orders</Link>
                </li>
                {/* <br/> */}
                <li className="p-4 mb-3">
                  <Link to={`/myprofile`}>My Profile</Link>
                </li>
                {/* <br/> */}
                <li className="p-4 mb-1">
                  <Link to={`/change-password`}>Change Password</Link>
                </li>
                {/* <br/> */}
                <li className="p-4 mb-3" onClick={() => logOut()}>
                  <Link>Logout</Link>
                </li>
              </>
            )}

            {/* <li className=''>
              <p id='mobileNavLinks'><a href="" >facial care</a> <span class="material-symbols-outlined text-end" style={{float:"right"}}>
                expand_more
              </span></p>
             
              
             
              <ul className='submobilemenu'>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                  <ul className='childmobilemenu'>
                    <li>about us </li>
                    <li>contactus</li>
                    <li>aptitude</li>
                  </ul>
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
              </ul>
            </li> */}

            {/* <li className=''>
              <p id='mobileNavLinks'><a href="" >facial care</a> <span class="material-symbols-outlined text-end" style={{float:"right"}}>
                expand_more
              </span></p>
             
              
             
              <ul className='submobilemenu'>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
              </ul>
            </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
