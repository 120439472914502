import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import OwlCarousel from "react-owl-carousel";
import axios from "axios";

export default function Topslider() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allSlider, setAllSlider] = useState([]);

  const slider = () => {
    axios
      .get(`${BASE_URL}getAllSlider`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          console.log(response.data.message);
          setAllSlider(response.data.data);
          console.log("all slider", response.data.data);
        }
      });
  };

  useEffect(() => {
    slider();
  }, []);
  return (
    <>
      <div className="">
        <Carousel
          // autoPlay
          showThumbs={false}
          style={{ backgroundColor: "#f9f9f9" }}
          autoPlay
          autoPlaySpeed={4000}
          infinite={true}
        >
          {allSlider.length > 0 &&
            allSlider.map((item, index) => (
              <div key={index}>
                <div
                  style={{
                    position: "absolute",
                    display: "inline-block",
                    width: "40%",
                    float: "left",
                  }}
                >
                  <p className="topslidertext1">
                    {item.heading1.toUpperCase()}
                  </p>
                  <p className="topslidertext2">{item.title}</p>
                  <p className="topslidertext3">{item.heading2}</p>
                  <br />
                  {/* <button type="button" className="loginbtn topsliderbutton">Discover More</button> */}
                </div>

                <img
                  className="img-fluid"
                  src={item.image}
                  alt=""
                  style={{ minHeight: "200px" }}
                />
              </div>
            ))}
          {/* <div >
            <h6></h6>
            <h3>

            </h3>
            <p></p>
            <img  className="img-fluid" src= "assets\image_CVIT5_v2.jpg" alt=''/></div>  
           <div>
           <h6></h6>
            <h3>

            </h3>
            <p></p>
            <img  className="img-fluid" src= "assets\image_NANOTECNOLOGIA.jpg" alt=''/></div>  
           <div>
           <h6></h6>
            <h3>

            </h3>
            <p></p>
            <img  className="img-fluid" src= "assets\image_SESLASH_BLACK_23.jpg" alt=''/></div>  
           <div>
           <h6></h6>
            <h3>

            </h3>
            <p></p>
            <img  className="img-fluid" src= "assets\image_SESMAHAL.jpg" alt=''/></div>  
           <div>
           <h6></h6>
            <h3>

            </h3>
            <p></p>
            <img className="img-fluid" src= "assets\img_OCEANSKIN.jpg" alt=''/></div>   */}
        </Carousel>
      </div>
    </>
  );
}
