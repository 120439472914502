import React, { useEffect, useState } from 'react'
import Layout from '../Component/Layout'
import { useParams } from 'react-router-dom'
import NavMenu from '../Component/NavMenu';
import Footer from '../Component/Footer';
import axios from 'axios';
import Productcard from '../Component/Productcard';
import FilterNav from '../Component/FilterNav';
import '../App.css';

export default function CategoriesView() {
  const slug=useParams();
  const [categorySlug , setCategorySlug]=useState("");
  const BASE_URL=process.env.REACT_APP_BASE_URL;

const AllCategory=()=>{
  axios
  .get(`${BASE_URL}getAllCategory`,
  {
    headers:{
      "version":"1.0.0",
      "Content-Type":"Application/json"
    }
  }).then((response)=>{
    if(response.data.success==false)
    {
      console.log(response.data.message);

    }
    else{
      setCategorySlug(response.data.data[0].slug);
      console.log(" category view slug",response.data.data[0].slug);
    }
  })
}
useEffect(()=>{
  AllCategory();
},[slug])

// useEffect(() => {
//   if (slug != undefined && slug != null) {
//     setCategorySlug(slug.slug);
//   }
// }, [slug]);

// if(slug!=undefined&&slug!=null)
// {
//   setCategorySlug(slug.slug);
// }

  return (
 <>
 {/* <Layout> */}
 <NavMenu/>
 <div className='cateview' style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
<h3 style={{fontSize:"40px",color:"#fff"}}>{slug.slug}</h3>
        </div>
        {/* <FilterNav/> */}
       <Productcard/>
<Footer/>

 {/* </Layout> */}
 </>
  )
}
